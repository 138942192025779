import React from "react";
import Visibility from "./Visibility";

export default function RadioInput(props) {
  return (
    <div className="input__outer">
      <div className="input__header">
        {props.label && <label>{props.label}</label>}
        {props.visibility && <Visibility />}
      </div>
      <div className="radio__outer">
        <div className="radio">
          <input
            checked={props.checked}
            name={props.type}
            type={props.type}
            onChange={props.onChange1}
          />
          <label htmlFor="type">{props.option1}</label>
        </div>
        <div className="radio">
          <input
            checked={props.checked}
            name={props.type}
            type={props.type}
            onChange={props.onChange2}
          />
          <label htmlFor="type">{props.option2}</label>
        </div>
      </div>
      {props.error && props.value === "" && (
        <div className="input__error">{props.errorText}</div>
      )}
    </div>
  );
}
