import React from "react";
import { chevronRight, closeIcon } from "../../../Base/SVG";
const near = [
  {
    avatar: "/images/avatar/avatar.png",
    name: "Tom M.",
    text: "Nearby Neighbour",
  },
  {
    avatar: "/images/avatar/avatar-2.png",
    name: "Cathy V.",
    text: "Nearby Neighbour",
  },
  {
    avatar: "/images/avatar/avatar-3.png",
    name: "Condo C.",
    text: "Nearby Neighbour",
  },
];
const more = [
  {
    avatar: "/images/avatar/pet-1.png",
  },
  {
    avatar: "/images/avatar/pet-2.png",
  },
  {
    avatar: "/images/avatar/pet-3.png",
  },
];
export default function Near() {
  return (
    <div className="feed__box">
      <div className="near">
        <div className="near__header">
          <h3>Meet new pets nearby</h3>
          <a href="#" className="link">
            See all
          </a>
        </div>
        <div className="near__body">
          {near.map((item, index) => {
            return (
              <div className="nearItem" key={index}>
                <div className="nearItem__info">
                  <div className="nearItem__info-avatar">
                    <img
                      src={process.env.PUBLIC_URL + item.avatar}
                      alt="avatar"
                    />
                  </div>
                  <div className="nearItem__info-content">
                    <h4>{item.name}</h4>
                    <h6>{item.text}</h6>
                  </div>
                </div>
                <div className="nearItem__btns">
                  <button type="button" className="button connect dark">
                    Connect
                  </button>
                  <button type="button" className="button close">
                    {closeIcon}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="near__footer">
          <div className="seePets">
            <div className="seePets__avatars">
              {more.map((item, index) => {
                return (
                  <div className="seePets__avatar" key={index}>
                    <img
                      src={process.env.PUBLIC_URL + item.avatar}
                      alt="avatar"
                    />
                  </div>
                );
              })}
            </div>
            <button type="button" className="link">
              See more pets
              {chevronRight}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
