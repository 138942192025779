export const BtnSliderModul = [
  { id: "1", text: "Pet Store" },
  { id: "2", text: "Pet Walker" },
  { id: "3", text: "Pet Sitter" },
  { id: "4", text: "Dog Sitter" },
  { id: "5", text: "Pet Store" },
  { id: "6", text: "Pet Walker" },
  { id: "7", text: "Pet Sitter" },
  { id: "8", text: "Dog Sitter" },
];
export const ListingSliderModul = [
  {
    id: "1",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "2",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "3",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "4",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "5",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "6",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "7",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
  {
    id: "8",
    image: "/images/explore/listing-1.png",
    title: "Super Pet Restaurant",
    name: "Pet-friendly Restaurant",
    detail: "Open",
  },
];
