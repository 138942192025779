import React, { useEffect, useState } from "react";
import TextInput from "../../../Base/Form/TextInput";
import DateInput from "../../../Base/Form/DateInput";
import RadioInput from "../../../Base/Form/RadioInput";
import { chevronLeft } from "../../../Base/SVG";
import { Link } from "react-router-dom";

export default function AddPet() {
  const [error, setError] = useState(true);

  const [form, setForm] = useState({
    petName: "",
    petType: "",
    petGender: "",
    type: "",
    petDate: "",
    petDate2: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  useEffect(() => {
    setError(form.petName === "" || form.petType === "" || form.petDate === "");
  }, [form]);

  return (
    <div className="profile">
      <div className="profile__inner">
        <div className="profileBack">
          <Link to="/profile"> {chevronLeft}</Link>
          <h3>Add New Pet</h3>
        </div>
        <div className="profileEdit visible">
          <div className="profileEdit__header">
            <Link to="/profile" className="profileEdit__back">
              {chevronLeft}
            </Link>
            <h3>Add New Pet</h3>
            <button type="button" className="save">
              Save
            </button>
          </div>
          <div className="profileEdit__inner">
            <TextInput
              placeholder="Enter Business Name"
              label="Name/Title"
              type="text"
              errorText="Please enter your business name"
              visibility={true}
              error={error}
              value={form.petName}
              onChange={onChangeInput("petName")}
            />
            <TextInput
              placeholder="Enter Pets Type"
              label="Pet Type"
              type="text"
              errorText="Please enter your pets type"
              error={error}
              visibility={true}
              value={form.petType}
              onChange={onChangeInput("petType")}
            />
            <TextInput
              placeholder="Enter Gender"
              label="Gender"
              type="text"
              errorText="Please enter your pet gender"
              error={error}
              visibility={true}
              value={form.petGender}
              onChange={onChangeInput("petGender")}
            />

            <RadioInput
              label="Spayed or Neutered"
              option1="Spayed"
              option2="Neutered"
              error={error}
              type="radio"
              name="type"
              visibility={true}
              errorText="Please select an option"
              onChange1={(e) => {
                if (e.target.checked) updateForm({ type: "spayed" });
              }}
              onChange2={(e) => {
                if (e.target.checked) updateForm({ type: "neutered" });
              }}
            />
            <DateInput
              selected={form.petDate}
              visibility={true}
              label="Date of Birth"
              startDate={form.petDate}
              errorText="Please enter the date of birth"
              placeholder="dd-mm-yyyy "
              onChange={(date) => updateForm({ petDate: date })}
            />
            <DateInput
              selected={form.petDate2}
              visibility={true}
              label="Date you came togather"
              startDate={form.petDate2}
              errorText="Please enter the date you came togather"
              placeholder="dd-mm-yyyy "
              onChange={(date) => updateForm({ petDate2: date })}
            />
            <div className="profileEdit__footer">
              <button type="button" className="formBtn clear">
                CLEAR
              </button>
              <button type="button" className="formBtn submit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
