import React from "react";
import Slider from "react-slick";
import NewsItem from "./NewsItem";
const news = [
  {
    image: "/images/news/news-1.jpg",
    title:
      "Veterinarian diagnosed with cancer shares lessons learned from pet patients",
    tags: "Pet, Dog",
    date: "Jan 2, 2023 by",
    name: "L. Baily",
  },
  {
    image: "/images/news/news-2.jpg",
    title:
      "Veterinarian diagnosed with cancer shares lessons learned from pet patients",
    tags: "Pet, Dog",
    date: "Jan 2, 2023 by",
    name: "L. Baily",
  },
  {
    image: "/images/news/news-3.jpg",
    title: "Veterinarian diagnosed with cancer shares ",
    tags: "Pet, Dog",
    date: "Jan 2, 2023 by",
    name: "L. Baily",
  },
  {
    image: "/images/news/news-1.jpg",
    title:
      "Veterinarian diagnosed with cancer shares lessons learned from pet patients",
    tags: "Pet, Dog",
    date: "Jan 2, 2023 by",
    name: "L. Baily",
  },
];
export default function News() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2.6,
    draggable: false,
    swipeToSlide: false,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          arrows: false,
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2.1,
        },
      },
    ],
  };
  return (
    <div className="feed__box">
      <div className="news">
        <div className="news__header">
          <h3>Latest Pet News</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <div className="news__body">
          <Slider {...settings}>
            {news.map((item, index) => {
              return <NewsItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
