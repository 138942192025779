import React from "react";
import { emailIcon, mapIcon, phoneIconFilled } from "../../../../Base/SVG";

export default function ProfileCommInfo() {
  return (
    <div className="profileGeneral">
      <div className="profileCommInfo">
        <h5 className="semi">Community Information</h5>
        <button type="button" className="profileCommInfo__link btn">
          {mapIcon} <h6 className="med">Boulevart, Sechelt, CA-BC</h6>
        </button>
        <a href="tel:1-403-352-4822" className="profileCommInfo__link">
          {phoneIconFilled} <h6 className="med">1-403-352-4822</h6>
        </a>
        <a
          href="mailto:avernureliving@gmail.com"
          className="profileCommInfo__link"
        >
          {emailIcon} <h6 className="med">avernureliving@gmail.com</h6>
        </a>
      </div>
    </div>
  );
}
