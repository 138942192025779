import React from "react";
import { Link } from "react-router-dom";

export default function SaleFreeItem(props) {
  return (
    <div className="saleFreeItem">
      <div className="saleFreeItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="free" />
      </div>
      <div className="saleFreeItem__content">
        <h4>Pet Grooming</h4>
        <p className="sm">Find pet grooming nearby</p>
        <Link to={props.link} className="button join" type="button">
          Browse now
        </Link>
      </div>
    </div>
  );
}
