import React from "react";
import ProfileBanner from "./components/ProfileBanner";
import ProfileBusinessHour from "./components/ProfileBusinessHour";
import ProfileCommPortal from "./components/ProfileCommPortal";
import ProfileDesc from "./components/ProfileDesc";
import ProfileInfo from "./components/ProfileInfo";
import ProfileMap from "./components/ProfileMap";
import ProfileMoreInfo from "./components/ProfileMoreInfo";
import ProfilePetParent from "./components/ProfilePetParent";
import ProfileQR from "./components/ProfileQR";
import ProfileSpokesPerson from "./components/ProfileSpokesPerson";

export default function ProfileBusiness() {
  return (
    <div className="profile single">
      <div className="profile__inner">
        <ProfileBanner image="/images/profile/profile-1.jpg" />
        <ProfileInfo
          type="business"
          title="Super Pet Restaurant - A good house for pet-friendly people...."
          name="Pet Store, Pet-friendly Restaurant"
        />
        <ProfileMap />
        <ProfileDesc />
        <ProfileSpokesPerson text="Become a Pet-friendly spokesperson for this “Business”" />
        <ProfilePetParent />
        <ProfileBusinessHour />
        <ProfileMoreInfo />
        <ProfileCommPortal />
        <ProfileQR />
      </div>
    </div>
  );
}
