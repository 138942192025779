import React, { useState , useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../Assets/logo.svg";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  // Params
  const { id } = useParams();

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const [cpassword, setCPassword] = useState("");
  const [cPasswordErr, setCPasswordErr] = useState("");
  const [cShowPassword, setCShowPassword] = useState(true);

  const [regexErr, setRegexErr] = useState("");

  const [loading, setLoading] = useState(false);

  // Validation
  // const isValidForm = () => {
  //   let isValidData = true;

  //   // const passwordRegex =
  //   //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,16}$/;

  //   const passwordRegex = /^[^\s]{6,}$/;

  //   if (!passwordRegex.test(password)) {
  //     setPasswordErr("Please match the below pattern!");
  //     isValidData = false;
  //   }

  //   if (password === "") {
  //     setPasswordErr("Required");
  //     isValidData = false;
  //   }

  //   if (cpassword === "") {
  //     setCPasswordErr("Required");
  //     isValidData = false;
  //   }

  //   if (password !== cpassword) {
  //     setCPasswordErr("Confirm password is incorrect");
  //     isValidData = false;
  //   }

  //   return isValidData;
  // };

  const isValidForm = () => {
    let isValidData = true;

    // Minimum length 6 and no white spaces
    const passwordRegex = /^[^\s]{6,}$/;

    // Validate password
    if (!passwordRegex.test(password)) {
      setPasswordErr(
        "Password must be at least 6 characters long with no spaces."
      );
      isValidData = false;
    }

    // Check if password is empty
    if (password === "") {
      setPasswordErr("Password is required.");
      isValidData = false;
    }

    // Check if confirm password is empty
    if (cpassword === "") {
      setCPasswordErr("Confirm password is required.");
      isValidData = false;
    }

    // Check if password and confirm password match
    if (password !== cpassword) {
      setCPasswordErr("Confirm password does not match.");
      isValidData = false;
    }

    return isValidData;
  };

  //   Handle Reset Password
  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      newPassword: password,
      resetToken: id,
    };

    if (isValidForm()) {
      setLoading(true);

      axios
        .post("https://dev.api.ship2mates.com/v1/auth/reset-password", payload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);

            setPassword("");
            setCPassword("");

            toast.success("Password Updated Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setLoading(false);
          }
        })
        .catch(({ response }) => {
          setLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <>
      <div className="parent_register">
        <div className="text-center">
          <img src={logo} alt="logo" className="logo_width mb-4" />
          <h4>Reset Password</h4>
          <p className="">Enter your new password here!</p>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Password<span className="staric">*</span>
              {passwordErr && (
                <span className="text-danger error-text ps-2">
                  {passwordErr}
                </span>
              )}
            </label>
            <div className="position-relative">
          
              <input
                type={showPassword ? "text" : "password"}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordErr("");
                }}
              />
              <div className="pas-icon-position cursor-pointer">
                {showPassword ? (
                  <BsFillEyeFill
                  size={19}
                  onClick={() => setShowPassword(!showPassword)}
                />
                ) : (
                  
                  <BsEyeSlashFill
                    size={19}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Confirm Password<span className="staric">*</span>
              {cPasswordErr && (
                <span className="text-danger error-text ps-2">
                  {cPasswordErr}
                </span>
              )}
            </label>
            <input type="text" name="dummy" style={{ display: "none" }} />

            <div className="position-relative">
              <input
                type={cShowPassword ? "text" : "password"}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter your password"
                value={cpassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                  setCPasswordErr("");
                }}
              />
              <div className="pas-icon-position cursor-pointer">
                {cShowPassword ? (
                  <BsFillEyeFill
                    size={19}
                    onClick={() => setCShowPassword(!cShowPassword)}
                  />
                ) : (
                  <BsEyeSlashFill
                    size={19}
                    onClick={() => setCShowPassword(!cShowPassword)}
                  />
                )}
              </div>
            </div>
          </div>

          <ul className="m-0 ps-3">
            {/* <li className="font-sm">
              Password must be between 8 and 16 characters
            </li> */}
            {/* <li className="font-sm">
              Password must include at least one lowercase letter
            </li>
            <li className="font-sm">
              Password must include at least one uppercase letter
            </li>
            <li className="font-sm">
              Password must include at least one number
            </li>
            <li className="font-sm">
              Password must include at least one special character
            </li> */}
          </ul>

          <button
            type="submit"
            class="btn btn-primary bg-btn w-100 mt-4"
            disabled={loading}
          >
            {loading ? (
              <div class="spinner-border spinner-border-sm" role="status"></div>
            ) : (
              "Reset Password"
            )}
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
