import React, { useEffect, useState } from "react";
import Home from "./Pages/Home/Home";
import Header from "./Base/Header";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Nav from "./Base/Nav";
import Sidebar from "./Base/Sidebar";
import Verify from "./Base/Verify";
import useCurrentPath from "./Base/Hooks/useCurrentPath";
import Header2 from "./Base/Header2";
import Market from "./Pages/Market/Market";
import Filter from "./Base/Filter";
import Profile from "./Pages/Account/Profile/Profile";
import EditProfile from "./Pages/Account/EditProfile/EditProfile";
import AddPet from "./Pages/Account/AddPet/AddPet";
import AddBusiness from "./Pages/Account/AddBusiness/AddBusiness";
import Modal from "./Base/Modals/Modal";
import Explore from "./Pages/Explore/Explore";
import ProfileBusiness from "./Pages/Explore/Profile/ProfileBusiness";
import ProfileApartment from "./Pages/Explore/Profile/ProfileApartment";
import Register from "./Pages/Register/Register";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";

function useRequiresVerification(currentPath) {
  const routesRequiringVerification = [
    "/",
    "/profile",
    "/edit-profile",
    "/add-pet",
    "/add-business",
    "/explore",
    "/profile-business",
    "/profile-apartment",
    "/marketplace",
    // Add other routes that require verification here
  ];

  return routesRequiringVerification.includes(currentPath);
}

export default function App() {
  const [isVerified, setIsVerified] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [exploreFilter, setExploreFilter] = useState(false);
  const [modal, setModal] = useState(false);

  let location = useLocation();

  const requiresVerification = useRequiresVerification(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  const header1 = useCurrentPath({
    routes: [
      "/",
      "profile",
      "edit-profile",
      "add-pet",
      "add-business",
      "explore",
      "profile-business",
      "profile-apartment",
    ],
  });
  const header2 = useCurrentPath({
    routes: ["marketplace"],
  });
  const filter = useCurrentPath({
    routes: ["marketplace"],
  });
  const exploreNav = useCurrentPath({
    routes: ["explore"],
  });
  const nav = useCurrentPath({
    routes: [
      "/",
      "profile",
      "edit-profile",
      "add-pet",
      "add-business",
      "explore",
      "profile-business",
      "profile-apartment",
    ],
  });
  const sidebar = useCurrentPath({
    routes: [
      "/",
      "profile",
      "edit-profile",
      "add-pet",
      "add-business",
      "profile-business",
      "profile-apartment",
    ],
  });
  const sidebarAdd = useCurrentPath({
    routes: ["profile-business", "profile-apartment"],
  });
  const removeMobile = useCurrentPath({
    routes: [
      "profile",
      "edit-profile",
      "add-pet",
      "add-business",
      "profile-business",
      "profile-apartment",
    ],
  });
  const removeMobile2 = useCurrentPath({
    routes: ["explore"],
  });
  return (
    <>
      {header1 && (
        <Header
          addClass={removeMobile ? "remove" : ""}
          filterBtn={removeMobile2}
          exploreFilter={exploreFilter}
          setExploreFilter={setExploreFilter}
        />
      )}
      {header2 && (
        <Header2
          setModal={setModal}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
        />
      )}
      <main className={"main " + (removeMobile ? "mR" : "")}>
        {/* {!isVerified && (
          <Verify
            addClass={removeMobile ? "remove" : removeMobile2 ? "remove" : ""}
          />
        )} */}
        {!isVerified &&
          requiresVerification && ( // Updated condition to exclude /register route
            <Verify
              addClass={removeMobile ? "remove" : removeMobile2 ? "remove" : ""}
            />
          )}

        <div className="wrapper">
          {filter && (
            <Filter filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
          )}
          {nav && (
            <Nav
              setModal={setModal}
              addClass={removeMobile ? "remove " : ""}
              addClass2={exploreNav ? "exp" : ""}
            />
          )}
          <Routes>
            <Route
              path=""
              index
              element={<Home modal={modal} setModal={setModal} />}
            />

            <Route
              path="/explore"
              element={
                <Explore
                  exploreFilter={exploreFilter}
                  setExploreFilter={setExploreFilter}
                />
              }
            />
            <Route path="/profile-business" element={<ProfileBusiness />} />
            <Route path="/profile-apartment" element={<ProfileApartment />} />
            <Route path="/marketplace" element={<Market />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/add-pet" element={<AddPet />} />
            <Route path="/add-business" element={<AddBusiness />} />

            {/* <Route path="/" element={<Navigate to="/register" />} /> */}
            <Route path="/register" element={<Register />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
          </Routes>
          {sidebar && <Sidebar sidebarAdd={sidebarAdd} />}
        </div>
      </main>
      {modal && <Modal active={modal} close={() => setModal(null)} />}
    </>
  );
}
