import React from "react";
import SaleItem from "./SaleItem";
const sales = [
  {
    id: "1",
    image: "/images/sale/1 (1).jpg",
    link: "/",
    title: "Used Dog bed",
    percent: 25,
    price: "$150",
    pricere: "$200",
    location: "16.1 km",
  },
  {
    id: "1",
    image: "/images/sale/1 (2).jpg",
    link: "/",
    title: "Cat things to play",
    percent: 25,
    price: "$150",
    pricere: "$200",
    location: "16.1 km",
  },
  {
    id: "1",
    image: "/images/sale/1 (3).jpg",
    link: "/",
    title: "Smart cat toy",
    percent: 25,
    price: "$150",
    pricere: "$200",
    location: "16.1 km",
  },
  {
    id: "1",
    image: "/images/sale/1 (4).jpg",
    link: "/",
    title: "Pet rainbow ball",
    percent: 25,
    price: "$150",
    pricere: "$200",
    location: "16.1 km",
  },
];
export default function Sale() {
  return (
    <div className="saled">
      <div className="marketTitle">
        <h2>Price Drop</h2>
        <a href="#" className="link">
          Sell all discounted products
        </a>
      </div>
      <div className="saled__row">
        {sales.map((item, index) => {
          return <SaleItem key={index} {...item} />;
        })}
      </div>
      <div className="marketTitle">
        <h2>Price Drop</h2>
      </div>
      <div className="saled__row">
        {sales.map((item, index) => {
          return <SaleItem key={index} {...item} />;
        })}
      </div>
    </div>
  );
}
