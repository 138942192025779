import React from "react";
import { circle, market, shareB } from "../../../../Base/SVG";
import { Link } from "react-router-dom";

export default function SaleItem(props) {
  return (
    <Link to="/" className="saledItem">
      <div className="saledItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="cats" />
        <div className="saledItem__more">
          <div className="saledItem__more-percent">
            {market} {props.percent}% off
          </div>
          <button className="saledItem__more-share" type="button">{shareB}</button>
        </div>
      </div>
      <div className="saledItem__content">
        <h4>{props.title}</h4>
        <h6>
          {props.price} <span>{props.pricere}</span> {circle} {props.location}
        </h6>
      </div>
    </Link>
  );
}
