import React from "react";
import Visibility from "./Visibility";

export default function TextArea({
  label,
  visibility,
  onChange,
  value,
  type,
  placeholder,
  error,
  errorText,
  row,
  textAreaRef,
}) {
  return (
    <div className="input__outer">
      <div className="input__header">
        {label && <label>{label}</label>}
        {visibility && <Visibility />}
      </div>
      <div className="input">
        <textarea
          ref={textAreaRef}
          cols="30"
          rows={row ? row : "5"}
          onChange={onChange}
          value={value}
          type={type}
          placeholder={placeholder}
        ></textarea>
      </div>
      {error && value === "" && <div className="input__error">{errorText}</div>}
    </div>
  );
}
