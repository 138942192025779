import React from "react";
import Slider from "react-slick";
import DeelItem from "./DeelItem";
const deels = [
  {
    image: "/images/slider/slider-1.jpg",
    title: "Peter Pet Grooming",
    text: "20% off on all kind of services from your near pet grooming service!",
    saved: 42,
  },
  {
    image: "/images/slider/slider-2.jpg",
    title: "Peter Pet Grooming",
    text: "20% off on all kind of services from your near pet grooming service!",
    saved: 42,
  },
  {
    image: "/images/slider/slider-3.jpg",
    title: "Peter Pet Grooming",
    text: "20% off on all kind of services from your near pet grooming service!",
    saved: 42,
  },
  {
    image: "/images/slider/slider-1.jpg",
    title: "Peter Pet Grooming",
    text: "20% off on all kind of services from your near pet grooming service!",
    saved: 42,
  },
];
export default function Deels() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          arrows: true,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 650,
        settings: {
          arrows: false,
          slidesToShow: 1.2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div className="feed__box">
      <div className="deel">
        <div className="deel__header">
          <h3>Local Deals</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <p className="mb">2 deals from businesses near you</p>
        <div className="deel__body">
          <Slider {...settings}>
            {deels.map((item, index) => {
              return <DeelItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
