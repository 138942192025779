import React from "react";
import Slider from "react-slick";
import ReelItem from "./ReelItem";
const reels = [
  {
    image: "/images/reels/reel-1.jpg",
    title: "Cute dogs playing with the child",
    views: "12.3k",
  },
  {
    image: "/images/reels/reel-2.jpg",
    title: "Cute dogs playing with the child",
    views: "4.3k",
  },
  {
    image: "/images/reels/reel-3.jpg",
    title: "Cute dogs playing with the child",
    views: "2.3k",
  },
  {
    image: "/images/reels/reel-1.jpg",
    title: "Cute dogs playing with the child",
    views: "6.3k",
  },
];
export default function Reels() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2.6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2.6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2.2,
        },
      },

    ],
  };
  return (
    <div className="feed__box">
      <div className="reel">
        <div className="reel__header">
          <h3>Reels and short videos</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <div className="reel__body">
          <Slider {...settings}>
            {reels.map((item, index) => {
              return <ReelItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
