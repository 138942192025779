import React from "react";
import { Link } from "react-router-dom";

export default function CatItem(props) {
  return (
    <Link to={props.link} className="catItem">
      <div className="catItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="cats" />
      </div>
      <div className="catItem__content">
        <h4>{props.title}</h4>
      </div>
    </Link>
  );
}
