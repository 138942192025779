import React from "react";
import { message, phoneIconEmpty, whatsAppIcon } from "../../../../Base/SVG";
import Stars from "../../../../Base/Widgets/Stars";
export default function ProfileInfo({ title, type, name }) {
  return (
    <div className={"profileInfo " + (type === "apartment" ? "apart" : "")}>
      {type === "business" && (
        <div className="profileInfo__avatar">
          <img
            src={process.env.PUBLIC_URL + "/images/profile/profile-2.png"}
            alt="profile"
          />
        </div>
      )}
      <div className="profileInfo__inner">
        <h4 className="sm">{title}</h4>
        <h6 className="sm red">{name}</h6>
        <div className="profileInfo__inner-row">
          <Stars starsRate={4.5} />
          <div className="profileInfo__inner-row-claim">
            <h6 className="sm semi">Claim Business</h6>
            <h6 className="sm semi green">Claimed</h6>
          </div>
        </div>
        <div className="profileInfo__inner-btns">
          <button className="button profileSocial red">
            {message} Message
          </button>
          <a href="tel:1-403-352-4822" className="button profileSocial blue">
            {phoneIconEmpty} Call
          </a>
          <a
            href="https:/www.whatsapp.com"
            className="button profileSocial green"
          >
            {whatsAppIcon} WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
}
