import React, { useState } from "react";
import Slider from "react-slick";

const ProfileGalleryModul = [
  { id: "1", image: "/images/profile/gallery/1 (1).jpg" },
  { id: "2", image: "/images/profile/gallery/1 (2).jpg" },
  { id: "3", image: "/images/profile/gallery/1 (3).jpg" },
  { id: "4", image: "/images/profile/gallery/1 (4).jpg" },
  { id: "5", image: "/images/profile/gallery/1 (5).jpg" },
  { id: "6", image: "/images/profile/gallery/1 (6).jpg" },
  { id: "7", image: "/images/profile/gallery/1 (5).jpg" },
  { id: "8", image: "/images/profile/gallery/1 (2).jpg" },
  { id: "9", image: "/images/profile/gallery/1 (4).jpg" },
  { id: "10", image: "/images/profile/gallery/1 (5).jpg" },
  { id: "11", image: "/images/profile/gallery/1 (3).jpg" },
  { id: "12", image: "/images/profile/gallery/1 (6).jpg" },
];

export default function ProfileGallery() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: false,
  };
  const settings2 = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    focusOnSelect: true,
    centerMode: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className="profileGeneral">
      <div className="profileGallery">
        <h5 className="semi">Image Gallery</h5>
        <div className="profileGallery__sliders">
          <div className="profileGallery__slider">
            <Slider
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              {...settings}
            >
              {ProfileGalleryModul.map((item, index) => {
                return <ProfileGalleryItem key={index} {...item} />;
              })}
            </Slider>
          </div>
          <div className="profileGallery__slider sm">
            <Slider
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              {...settings2}
            >
              {ProfileGalleryModul.map((item, index) => {
                return <ProfileGalleryItem key={index} {...item} />;
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

const ProfileGalleryItem = (props) => {
  return (
    <div className="profileGallery__slider-item">
      <div className="profileGallery__slider-item-image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </div>
    </div>
  );
};
