import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../Form/TextArea";
import SelectPrivacy from "../../SelectPrivacy";
import {
  calendarIcon,
  callLinkIcon,
  chevronBottom,
  clockIcon,
  closeIcon,
  eventIcon,
  house,
  imageIcon,
  locationIcon,
  pollIcon,
  sellIcon,
  usersOne,
  usersThree,
  usersTwo,
  world,
} from "../../SVG";
import ImageList from "./ImageList";

const selectPrivacyList = [
  {
    id: "1",
    value: "Anyone",
    desc: "anyone on or off MyPetsWelcome™",
    svg: world,
  },
  {
    id: "2",
    value: "Nearby neighborhoods",
    desc: "anyone within 30 miles",
    svg: house,
  },
  {
    id: "3",
    value: "All members",
    desc: "anyone on MyPetsWelcome™",
    svg: usersThree,
  },
  {
    id: "4",
    value: "Friends",
    desc: "only connected with your profile",
    svg: usersTwo,
  },
  {
    id: "5",
    value: "Only me",
    desc: "only you and admins can see that",
    svg: usersOne,
  },
];
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function ModalMain({
  close,
  initForm,
  form,
  updateForm,
  expand,
  setExpand,
  updateImages,
  setModal,
  setInput,
  setForm,
  initStartTime,
  setModalName,
  modalName,
  mindPlaceholder,
  setMindPlaceholder,
  setDiscard,
}) {
  const textAreaRef = useRef(null);
  const btnDelPoll = useRef(null);
  const btnDelEvent = useRef(null);
  const btnDelMap = useRef(null);
  const btnSeeChoice = useRef(null);

  const [select, setSelect] = useState(1);
  const [showChoices, setShowChoices] = useState(false);
  const [choiceList, setChoiceList] = useState(form.poll.choice);
  useEffect(() => {
    if (showChoices) {
      setChoiceList(form.poll.choice);
    } else {
      setChoiceList(form.poll.choice.slice(0, 2));
    }
  }, [showChoices]);
  useEffect(() => {
    if (
      form.poll.question !== "" ||
      form.pEvent.name !== "" ||
      form.mind !== ""
    ) {
      setExpand(true);
    } else setExpand(false);
  }, [form.poll.question, form.pEvent.name, form.mind]);

  const changeMedium = (item) => {
    setSelect(item.value);
  };
  const deletePoll = () => {
    setMindPlaceholder("What’s on your mind?");
    setForm({
      ...form,
      poll: { question: "", choice: ["", ""] },
    });
  };
  const deleteEvent = () => {
    setForm({
      ...form,
      location: "",
      mind: "",
      pEvent: {
        name: "",
        coverImage: "/images/modal/eventCover.png",
        startDate: new Date(),
        startTime: initStartTime,
        endDate: null,
        endTime: null,
        videoCallLink: "",
      },
    });
  };
  const deleteMap = () => {
    setForm({
      ...form,
      location: "",
    });
  };
  const clickFuncPoll = (e) => {
    if (e.target !== btnDelPoll.current && e.target !== btnSeeChoice.current) {
      setModal("poll");
    } else if (e.target === btnDelPoll.current) deletePoll();
    else {
      setShowChoices(!showChoices);
    }
  };
  const clickFuncEvent = (e) => {
    if (e.target !== btnDelEvent.current) {
      setModal("event");
    } else {
      deleteEvent();
    }
  };
  const clickFuncMap = (e) => {
    if (e.target !== btnDelMap.current) {
      setModal("map");
      setModalName("main");
    } else {
      deleteMap();
    }
  };
  const dateFormat = (date) => {
    let day = days[date.getDay()];
    let month = months[date.getMonth()];
    let dateOfMonth = date.getDate();

    const strDate = `${day}, ${month} ${dateOfMonth}`;

    return strDate;
  };
  const timeFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };
  const updateHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 0;
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };
  useEffect(() => {
    updateHeight();
  }, [form.mind]);
  const postBtnval = () => {
    return (
      form.poll.question !== "" || form.mind !== "" || form.images.length !== 0
    );
  };
  return (
    <div className={"modal__inner " + (expand ? "pb " : "")}>
      <div
        className="modal__inner-close"
        onClick={() => {
          if (JSON.stringify(form) === JSON.stringify(initForm)) {
            close();
          } else {
            setDiscard(true);
          }
        }}
      >
        {closeIcon}
      </div>
      <div className="modal__inner-title">
        <SelectPrivacy
          list={selectPrivacyList}
          selected={selectPrivacyList[0]}
          onChange={changeMedium}
        />

        <button
          className="button post sm"
          disabled={!postBtnval()}
          onClick={close}
        >
          Post
        </button>
      </div>
      <div className={"modalContent " + (expand ? "large" : "")}>
        {form.images.length > 0 && (
          <ImageList
            form={form}
            updateForm={updateForm}
            imgList={form.images}
          />
        )}

        <TextArea
          textAreaRef={textAreaRef}
          row="1"
          value={form.mind}
          onChange={setInput("mind")}
          placeholder={mindPlaceholder}
        />
        {form.location === "" ? (
          <div className="modalContent__location">
            <div
              className="modalContent__location-inner"
              onClick={() => {
                setModal("map");
                setModalName("main");
              }}
            >
              {locationIcon} Add location
            </div>
          </div>
        ) : modalName === "main" ? (
          <div className="modalContent__location mb">
            <div className="modalContent__map" onClick={clickFuncMap}>
              <div className="modalContent__map-close" ref={btnDelMap}>
                {closeIcon}
              </div>
              <div className="modalContent__map-text">
                <h5 className="semi">{form.location}</h5>
              </div>
              <div className="modalContent__map-content">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.3837130424486!2d-106.87690998404983!3d52.83347052070267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53041f0a6a6707d5%3A0x3005d58c2ab7bb2a!2sHorner%20Ave%2C%20Blaine%20Lake%2C%20SK%20S0J%200J0!5e0!3m2!1sru!2sca!4v1674230584344!5m2!1sru!2sca"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {form.poll.question !== "" && (
          <div className="modalContent__poll" onClick={clickFuncPoll}>
            <div className="modalContent__poll-close" ref={btnDelPoll}>
              {closeIcon}
            </div>
            <p>{form.poll.question}</p>
            {choiceList.map((item, index) => {
              return (
                <div key={index} className="modalContent__poll-choice">
                  {item}
                </div>
              );
            })}
            {form.poll.choice.length > 2 && (
              <button ref={btnSeeChoice} className="button choice">
                {showChoices ? (
                  <>Collapse</>
                ) : (
                  <>See all {form.poll.choice.length} choices</>
                )}
              </button>
            )}
          </div>
        )}
        {form.pEvent.name !== "" && (
          <div className="modalContent__event" onClick={clickFuncEvent}>
            <div className="modalContent__event-close" ref={btnDelEvent}>
              {closeIcon}
            </div>
            <div className="modalContent__event-image">
              <img src={form.pEvent.coverImage} alt="" />
            </div>
            <div className="modalContent__event-inner">
              <div className="modalContent__event-title">
                <div className="modalContent__event-title-date">
                  {calendarIcon}
                  {dateFormat(form.pEvent.startDate)}
                </div>
                <div className="modalContent__event-title-time">
                  {clockIcon}
                  {timeFormat(form.pEvent.startTime)}
                </div>
              </div>
              <h4>{form.pEvent.name}</h4>
              {form.location !== "" ? (
                <div className="modalContent__event-location">
                  {locationIcon} {form.location}
                </div>
              ) : (
                ""
              )}
              {form.pEvent.videoCallLink !== "" ? (
                <div className="modalContent__event-link">
                  {callLinkIcon} Online event
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={
          "modalNav " +
          (expand ? "sm " : "") +
          (form.poll.question !== "" || form.pEvent.name !== "" ? "solo" : "")
        }
      >
        <h6 className="semi">Add to your post</h6>
        <div className="modalNav__btns">
          <div
            className="modalNav__btn red"
            onClick={() => {
              setModal("sell");
            }}
          >
            <div className="modalNav__btn-icon">{sellIcon}</div>
            <span> Sell or give away an item</span>
            <div className="modalNav__btn-tooltip">
              Sell or give away an item
            </div>
          </div>
          <div className="modalNav__btn green">
            <div className="modalNav__btn-icon">{imageIcon}</div>
            <span>Take a photo or video</span>
            <input
              type="file"
              accept="image/png , image/jpeg"
              multiple
              onChange={(e) => {
                updateImages(e.target);
                setExpand(true);
                setMindPlaceholder("Add a caption");
              }}
            />
            <div className="modalNav__btn-tooltip">Take a photo or video</div>
          </div>
          <div
            className="modalNav__btn blue"
            onClick={() => {
              setModal("event");
              setModalName("event");
            }}
          >
            <div className="modalNav__btn-icon">{eventIcon}</div>
            <span> Create an event</span>
            <div className="modalNav__btn-tooltip">Create an event</div>
          </div>
          <div
            className="modalNav__btn purple"
            onClick={() => {
              setModal("poll");
            }}
          >
            <div className="modalNav__btn-icon">{pollIcon}</div>
            <span> Poll your neighbor</span>
            <div className="modalNav__btn-tooltip">Poll your neighbor</div>
          </div>
          <div
            className="modalNav__btn expand"
            onClick={() => setExpand(false)}
          >
            {chevronBottom}
            <div className="modalNav__btn-tooltip">Expand</div>
          </div>
        </div>
      </div>
    </div>
  );
}
