import React, { useEffect, useRef, useState } from "react";
import { box, help, listed, lock, user } from "./SVG";
import { Link } from "react-router-dom";

export default function Avatar() {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  const clickListener = React.useCallback((e) => {
    const className = e.target.classList[0];
    if (!className || !className.startsWith("account")) setActive(false);
    if (className === "account__list-link") setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      setTimeout(() => document.addEventListener("click", clickListener), 100);
    } else document.removeEventListener("click", clickListener);
    return () => document.removeEventListener("click", clickListener);
  }, [active]);
  return (
    <div className="avatar" ref={wrapper}>
      <button className="avatar__btn" onClick={toggleActive} type="button">
        <img src="images/avatar/avatar.png" alt="avatar" />
      </button>
      <div className={`dropdown  ${active ? "active" : ""} `}>
        <Link to="/profile" className="dropdown__link">
          {user} View Profile
        </Link>
        <Link to="/" className="dropdown__link">
          {lock} Settings
        </Link>
        <Link to="/" className="dropdown__link">
          {help} Help
        </Link>
        <Link to="/" className="dropdown__link">
          {box} Sign out
        </Link>
      </div>
    </div>
  );
}
