import React, { useRef, useState } from "react";
import Checkbox from "../../Form/Checkbox";
import RadioInputPost from "../../Form/RadioInputPost";
import TextArea from "../../Form/TextArea";
import TextInput from "../../Form/TextInput";
import {
  arrowLeft,
  chevronBottom,
  closeIcon,
  locationIcon,
  uploadIcon,
} from "../../SVG";
import ImageList from "../ModalMain/ImageList";

const initForm = {
  area: "Your broader local area",
  category: "",
  images: [],
  title: "",
  price: "",
  desc: "",
};
const categoryList = [
  "Appliances",
  "Baby & Kids",
  "Bicycles",
  "Clothing & Accessories",
  "Electronics",
  "Furniture",
  "Garage Sales",
  "Garden",
  "Home decor",
  "Home sales",
  " Motoring",
  "Musical instruments",
  "Neighbour made",
  "Neighbour services",
  "Other",
  "Pet supplies",
  "Property rentals",
  "Sports & Outdoors",
  "Tickets",
  "Tools",
  "Toys & Games",
  "Wanted",
];

export default function ModalSell({ setModal, close }) {
  const checkRef = useRef(null);
  const [form, setForm] = useState(initForm);
  const [area, setArea] = useState(false);
  const [cat, setCat] = useState(false);
  const [errorText, setErrorText] = useState();
  const updateImages = (target) => {
    setForm({ ...form, images: [...form.images, ...target.files] });
  };
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  const postBtnVal = () => {
    if (form.title === "") {
      setErrorText("Please add a title to your listing.");
    } else if (form.desc === "") {
      setErrorText("Please add a description for your listing.");
    } else if (form.category === "") {
      setErrorText("Please choose a category for your listing.");
    } else if (form.images.length === 0) {
      setErrorText("Please add at least one photo.");
    } else {
      close();
    }
  };
  return (
    <div className="modal__inner sell">
      <div className="modal__inner-back" onClick={() => setModal("main")}>
        {arrowLeft}
      </div>
      <div className="modal__inner-close" onClick={close}>
        {closeIcon}
      </div>
      <div className="modalSell">
        <div className="modalSell__inner">
          {errorText && (
            <div className="modalSell__inner-error">{errorText}</div>
          )}
          <div className={"modalSell__dropdown " + (area ? "active" : "")}>
            <div className="modalSell__dropdown-image">
              {form.area === "Your broader local area" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/modal/area.png"}
                  alt="area"
                />
              )}
              {form.area === "Your nearby neighborhoods" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/modal/area2.png"}
                  alt="area2"
                />
              )}
              {form.area === "Just your neighborhood" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/modal/area3.png"}
                  alt="area3"
                />
              )}
            </div>
            <div
              className="modalSell__dropdown-title"
              onClick={() => setArea(!area)}
            >
              <div className="modalSell__dropdown-title-text">
                {locationIcon}
                {form.area}
              </div>
              <div className="modalSell__dropdown-title-arrow">
                {chevronBottom}
              </div>
            </div>
            {area && (
              <div className="modalSell__dropdown-content">
                <RadioInputPost
                  option="Your broader local area"
                  type="radio"
                  onChange={(e) => {
                    if (e.target.checked)
                      updateForm({ area: "Your broader local area" });
                    setArea(false);
                  }}
                />
                <RadioInputPost
                  option="Your nearby neighborhoods"
                  type="radio"
                  onChange={(e) => {
                    if (e.target.checked)
                      updateForm({ area: "Your nearby neighborhoods" });
                    setArea(false);
                  }}
                />
                <RadioInputPost
                  option="Just your neighborhood"
                  type="radio"
                  onChange={(e) => {
                    if (e.target.checked)
                      updateForm({ area: "Just your neighborhood" });
                    setArea(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className={"modalSell__dropdown " + (cat ? "active" : "")}>
            <div
              className="modalSell__dropdown-title"
              onClick={() => setCat(!cat)}
            >
              <div className="modalSell__dropdown-title-text">
                {form.category !== "" ? form.category : "Item category"}
              </div>
              <div className="modalSell__dropdown-title-arrow">
                {chevronBottom}
              </div>
            </div>
            {cat && (
              <div className="modalSell__dropdown-content">
                {categoryList.map((item, index) => {
                  return (
                    <RadioInputPost
                      key={index}
                      option={item}
                      type="radio"
                      onChange={(e) => {
                        if (e.target.checked) updateForm({ category: item });
                        setCat(false);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="modalSell__inner-title">
            <TextInput
              type="text"
              value={form.title}
              placeholder="Title"
              onChange={setInput("title")}
            />
          </div>
          <div className="modalSell__inner-price">
            Price
            <div className="modalSell__inner-price-inp">
              $
              <TextInput
                type="number"
                value={form.price === "free" ? "" : form.price}
                onChange={setInput("price")}
                onFocus={() => {
                  checkRef.current.checked = false;
                }}
              />
            </div>
            <Checkbox
              checkRef={checkRef}
              text="Free"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) updateForm({ price: "free" });
              }}
            />
          </div>
          <div className="modalSell__inner-desc">
            <TextArea
              row="1"
              value={form.desc}
              onChange={setInput("desc")}
              placeholder="Description"
            />
          </div>
          {form.images.length > 0 && (
            <ImageList
              form={form}
              updateForm={updateForm}
              imgList={form.images}
            />
          )}
        </div>
      </div>
      <div className="modal__inner-footer">
        <div className="modal__inner-footer-btn">
          {uploadIcon}
          Add photo
          <input
            type="file"
            accept="image/png , image/jpeg"
            multiple
            onChange={(e) => {
              updateImages(e.target);
            }}
          />
        </div>
        <button className="button post sm" onClick={postBtnVal}>
          Post listing
        </button>
      </div>
    </div>
  );
}
