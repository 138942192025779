import React from "react";

const ProfileAvailableModul = [
  {
    id: "1",
    image: "/images/profile/gallery/1 (1).jpg",
    title: "Classic 2 Bedroom - 105",
    price: "$1057",
    type: "Classic 2 Bedroom",
    desc: "2 Beds, 1 Bath, 844 Sq ft",
  },
  {
    id: "2",
    image: "/images/profile/gallery/1 (2).jpg",
    title: "Classic 1 Bedroom - 308",
    price: "$759",
    type: "Classic 1 Bedroom",
    desc: "1 Beds, 1 Bath, 440 Sq ft",
  },
];

export default function ProfileAvailable() {
  return (
    <div className="profileGeneral">
      <div className="profileAvailable">
        <h5 className="semi">Available Units</h5>
        <div className="profileAvailable__inner">
          {ProfileAvailableModul.map((item, index) => {
            return <ProfileAvailableItem key={index} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
}
const ProfileAvailableItem = (props) => {
  return (
    <div className="profileAvailable__item">
      <div className="profileAvailable__item-image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </div>
      <div className="profileAvailable__item-content">
        <h6 className="med">{props.title}</h6>
        <h6 className="med green">{props.price}</h6>
        <h6 className="sm semi">{props.type}</h6>
        <h6 className="sm med">{props.desc}</h6>
      </div>
    </div>
  );
};
