import React from "react";

export default function Verify({ addClass }) {
  return (
    <div className={"verify " + addClass}>
      <div className="auto__container">
        <div className="verify__inner">
          <h6 className="med">
            Want to know more? <a href="#">Verify your account</a> to join this
            neighborhood.
          </h6>
        </div>
      </div>
    </div>
  );
}
