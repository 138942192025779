import React from "react";
import { linkIсon } from "../../../../Base/SVG";

export default function ProfileMoreInfo() {
  return (
    <div className="profileGeneral">
      <div className="profileMoreInfo">
        <h5 className="semi">More Information</h5>
        <a href="#" className="profileMoreInfo__link">
          {linkIсon} <h6 className="med">Visit website</h6>
        </a>
      </div>
    </div>
  );
}
