import React, { useEffect, useRef, useState } from "react";

export default function SelectInput({
  selected = null,
  list,
  onChange,
  error,
  value,
  errorText,
}) {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentSelected, setCurrentSelected] = useState(selected);
  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  return (
    <div className={"select " + (active ? "active" : "")} ref={wrapper}>
      <div className="select__selected" onClick={toggleActive}>
        {currentSelected ? currentSelected.value : "-----"}
      </div>
      {error && value === "" && (
        <div className="select__error">{errorText}</div>
      )}
      <ul className={"select__options " + (active ? "active" : "")}>
        {list.map((item, index) => (
          <li
            className="select__option"
            key={index}
            onClick={() => onClick(item)}
          >
            {item.value}
          </li>
        ))}
      </ul>
    </div>
  );
}
