import React from "react";
import Slider from "react-slick";
import { listIcon } from "../../../../Base/SVG";
import SliderBtnItem from "./BtnItem";
import ListingItem from "./ListingItem";
import { BtnSliderModul, ListingSliderModul } from "./SliderModul";

export default function ExploreMap({ listView, setListView }) {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3.4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 359,
        settings: {
          arrows: false,
          slidesToShow: 3.2,
          dots: false,
        },
      },
      {
        breakpoint: 340,
        settings: {
          arrows: false,
          slidesToShow: 2.6,
          dots: false,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 390,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "40px",
          dots: false,
        },
      },
      {
        breakpoint: 340,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "30px",
          dots: false,
        },
      },
    ],
  };
  return (
    <div className={"explore__map " + (listView ? "" : "active")}>
      <div className="exploreBtnSlider">
        <Slider {...settings}>
          {BtnSliderModul.map((item, index) => {
            return <SliderBtnItem key={index} {...item} />;
          })}
        </Slider>
      </div>
      <div className="exploreListingSlider">
        <Slider {...settings2}>
          {ListingSliderModul.map((item, index) => {
            return <ListingItem key={index} {...item} />;
          })}
        </Slider>
      </div>
      <button className="button view" onClick={() => setListView(true)}>
        {listIcon} List View
      </button>
      <div className="explore__map-inner">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.3837130424486!2d-106.87690998404983!3d52.83347052070267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53041f0a6a6707d5%3A0x3005d58c2ab7bb2a!2sHorner%20Ave%2C%20Blaine%20Lake%2C%20SK%20S0J%200J0!5e0!3m2!1sru!2sca!4v1674230584344!5m2!1sru!2sca"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
