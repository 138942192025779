import React from "react";
import { arrowLeft } from "../../SVG";
import ChoiceList from "./ChoiceList";

export default function ModalPoll({
  setModal,
  form,
  updateForm,
  setMindPlaceholder,
  initForm,
  setDiscard,
  setExpand,
}) {
  const btnValidation = () => {
    const checkEmpty = form.poll.choice.find((item) => item === "");
    return form.poll.question !== "" && checkEmpty !== "";
  };
  return (
    <div className="modal__inner poll">
      <div
        className="modal__inner-back"
        onClick={() => {
          if (
            JSON.stringify(form.poll) === JSON.stringify(initForm.poll) &&
            form.mind === "" &&
            form.location === ""
          ) {
            setModal("main");
          } else {
            setDiscard(true);
          }
        }}
      >
        {arrowLeft}
      </div>
      <div className="modal__inner-title">
        <h3>Create a poll</h3>
        <button
          className="button post sm"
          disabled={!btnValidation()}
          onClick={() => {
            setExpand(true);
            setModal("main");
            setMindPlaceholder("Add a descripton (optional)");
          }}
        >
          Done
        </button>
      </div>
      <ChoiceList
        poll={form.poll}
        setPoll={(data) => updateForm({ poll: data })}
      />
    </div>
  );
}
