import React from "react";

export default function ProfileQR() {
  return (
    <div className="profileGeneral">
      <div className="profileQR">
        <h5 className="semi">Business QR Code</h5>
        <div className="profileQR__image">
          <img
            src={process.env.PUBLIC_URL + "images/profile/qr.png"}
            alt="qr"
          />
        </div>
      </div>
    </div>
  );
}
