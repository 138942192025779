import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  chevronLeft,
  copyIcon,
  emailIcon,
  facebookIcon,
  heartEmpty,
  listed,
  repostIcon,
  shareIconEmpty,
  twitterIcon,
  whatsAppIcon,
} from "../../../../Base/SVG";
export default function ProfileBanner({ image }) {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  return ( 
    <div className="profileBanner">
      <Link to="/explore" className="profileBanner__back">
        {chevronLeft}
      </Link>
      <div className="profileBanner__edit">{listed}</div>
      <div className="profileBanner__btns">
        <button type="button" className="profileBanner__btns-like">
          {heartEmpty} <span>10</span>
        </button>
        <div
          className={"profileBanner__btns-share " + (active ? "active" : "")}
          ref={wrapper}
        >
          <div
            className="profileBanner__btns-share-title"
            onClick={toggleActive}
          >
            {shareIconEmpty} <span>Share</span>
          </div>
          <div className="profileBanner__btns-share-content">
            <button
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">{repostIcon}</div>
              <span>Repost</span>
            </button>
            <button
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">{copyIcon}</div>
              <span>Copy link</span>
            </button>
            <button
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">{emailIcon}</div>
              <span>Email</span>
            </button>
            <a
              href="https:/www.facebook.com"
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">
                {facebookIcon}
              </div>
              <span>Facebook</span>
            </a>
            <a
              href="https:/www.twitter.com"
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">
                {twitterIcon}
              </div>
              <span>Twitter</span>
            </a>
            <a
              href="https:/www.whatsapp.com"
              className="profileBanner__btns-share-btn"
              onClick={() => setActive(false)}
            >
              <div className="profileBanner__btns-share-icon">
                {whatsAppIcon}
              </div>
              <span>WhatsApp</span>
            </a>
          </div>
        </div>
      </div>
      <div className="profileBanner__image">
        <img src={process.env.PUBLIC_URL + image} alt="banner" />
      </div>
    </div>
  );
}
