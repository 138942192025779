import React from "react";
import ListedItem from "./ListedItem";
import Slider from "react-slick";
const lists = [
  {
    id: "1",
    image: "/images/listed/1 (1).jpg",
    link: "/",
    title: "Dog carrier backpack",
    price: "$50",
    location: "16.1 km",
  },
  {
    id: "2",
    image: "/images/listed/1 (2).jpg",
    link: "/",
    title: "Cat Bed",
    price: "$50",
    location: "16.1 km",
  },
  {
    id: "1",
    image: "/images/listed/1 (1).jpg",
    link: "/",
    title: "Dog carrier backpack",
    price: "$50",
    location: "16.1 km",
  },
  {
    id: "3",
    image: "/images/listed/1 (2).jpg",
    link: "/",
    title: "Cat Bed",
    price: "$50",
    location: "16.1 km",
  },
];
export default function Listed() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.6,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.8,
        },
      },
    ],
  };
  return (
    <div className="listed">
      <div className="marketTitle">
        <h2>Just Listed</h2>
        <a href="#" className="link">
          See all
        </a>
      </div>
      <div className="listed__slider">
        <Slider {...settings}>
          {lists.map((item, index) => {
            return <ListedItem key={index} {...item} />;
          })}
        </Slider>
      </div>
    </div>
  );
}
