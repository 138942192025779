import React from "react";
import { mapIcon } from "../../../Base/SVG";
import BusinessList from "./Listing/BusinessList";
import EventList from "./Listing/EventList";
import HouseList from "./Listing/HouseList";
import JobList from "./Listing/JobList";
import ProductList from "./Listing/ProductList";
import ServiceList from "./Listing/ServiceList";

export default function ExploreListing({ setListView, listView }) {
  return (
    <div className={"explore__listing " + (listView ? "active" : "")}>
      <button className="button view" onClick={() => setListView(false)}>
        {mapIcon} Map View
      </button>
      <div className="explore__listing-inner">
        <BusinessList
          image="/images/explore/listing-1.png"
          title="Super Pet Restaurant - A good house for pet-friendly people...."
          name="Pet-friendly Restaurant"
          location="Toronto, Ontorio, Canada"
          detail="Open Now"
          starsRate={4.5}
        />
        <HouseList
          image="/images/explore/listing-2.png"
          title="Upper Montery Estates (Canada) - A pet-friendly Apartment"
          name="Pet-friendly Apartment"
          location="Toronto, Ontorio, Canada"
          detail="Avenue Living Comminuties"
          starsRate={3}
        />
        <ServiceList
          image="/images/explore/listing-3.png"
          title="Classic Bedroom 1 - Upper Montery Estate"
          name="Upper Montery Estate"
          location="Toronto, Ontorio, Canada"
          detail="Avenue Living Comminuties"
          desc="1 Bath 2 Beds 1200 Sq ft"
        />
        <EventList
          image="/images/explore/listing-4.png"
          title="Internations Dog Day - An Event for Pet-friendly People"
          name="Dog Day"
          location="Toronto, Ontorio, Canada"
          date="23-11-2022 @ 11:30 PM"
        />
        <JobList
          image="/images/explore/listing-5.png"
          title="Marketing Executive - Its a Pet-friendly Job Post"
          name="Upper Montery Limited"
          location="Toronto, Ontorio, Canada"
          jobtime="Full Time"
        />
        <ProductList
          image="/images/explore/listing-6.png"
          title="Dog toys for dogs funning toy bite Toys for dogs and ..."
          name="Dog Toys"
          location="Toronto, Ontorio, Canada"
          price="189.00"
          free={true}
        />
        <BusinessList
          image="/images/explore/listing-1.png"
          title="Super Pet Restaurant - A good house for pet-friendly people...."
          name="Pet-friendly Restaurant"
          location="Toronto, Ontorio, Canada"
          detail="Open Now"
          starsRate={4.5}
        />
        <HouseList
          image="/images/explore/listing-2.png"
          title="Upper Montery Estates (Canada) - A pet-friendly Apartment"
          name="Pet-friendly Apartment"
          location="Toronto, Ontorio, Canada"
          detail="Avenue Living Comminuties"
          starsRate={3}
        />
        <ServiceList
          image="/images/explore/listing-3.png"
          title="Classic Bedroom 1 - Upper Montery Estate"
          name="Upper Montery Estate"
          location="Toronto, Ontorio, Canada"
          detail="Avenue Living Comminuties"
          desc="1 Bath 2 Beds 1200 Sq ft"
        />
        <EventList
          image="/images/explore/listing-4.png"
          title="Internations Dog Day - An Event for Pet-friendly People"
          name="Dog Day"
          location="Toronto, Ontorio, Canada"
          date="23-11-2022 @ 11:30 PM"
        />
        <JobList
          image="/images/explore/listing-5.png"
          title="Marketing Executive - Its a Pet-friendly Job Post"
          name="Upper Montery Limited"
          location="Toronto, Ontorio, Canada"
          jobtime="Full Time"
        />
        <ProductList
          image="/images/explore/listing-6.png"
          title="Dog toys for dogs funning toy bite Toys for dogs and ..."
          name="Dog Toys"
          location="Toronto, Ontorio, Canada"
          price="189.00"
          free={true}
        />
      </div>
    </div>
  );
}
