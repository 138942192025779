import React from "react";
import ProfileAmenities from "./components/ProfileAmenities";
import ProfileAvailable from "./components/ProfileAvailable";
import ProfileBanner from "./components/ProfileBanner";
import ProfileBusinessHour from "./components/ProfileBusinessHour";
import ProfileCommInfo from "./components/ProfileCommInfo";
import ProfileCommPortal from "./components/ProfileCommPortal";
import ProfileDesc from "./components/ProfileDesc";
import ProfileGallery from "./components/ProfileGallery";
import ProfileInfo from "./components/ProfileInfo";
import ProfileMap from "./components/ProfileMap";
import ProfileMoreInfo from "./components/ProfileMoreInfo";
import ProfilePetParent from "./components/ProfilePetParent";
import ProfileQR from "./components/ProfileQR";
import ProfileSpokesPerson from "./components/ProfileSpokesPerson";

export default function ProfileApartment() {
  return (
    <div className="profile single">
      <div className="profile__inner">
        <ProfileBanner image="/images/profile/profile-3.jpg" />
        <ProfileInfo
          type="apartment"
          title="Upper Montery Estates (Canada) - A pet-friendly Apartment"
          name="Pet-friendly Apartment"
        />
        <ProfileMap />
        <ProfileDesc />
        <ProfileGallery />
        <ProfileAvailable />
        <ProfileAmenities />
        <ProfileSpokesPerson text="Become a Pet-friendly spokesperson for this “Apartment”" />
        <ProfilePetParent />
        <ProfileBusinessHour />
        <ProfileCommInfo />
        <ProfileMoreInfo />
        <ProfileCommPortal />
        <ProfileQR />
      </div>
    </div>
  );
}
