import React from "react";

export default function Stars({ starsRate }) {
  const width = (starsRate * 100) / 5;
  return (
    <div className="stars">
      <div className="stars__empty">
        <img
          src={process.env.PUBLIC_URL + "/images/icons/stars.png"}
          alt="stars"
        />
      </div>
      <div className="stars__filled" style={{ width: width + "%" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/icons/stars-filled.png"}
          alt="stars"
        />
      </div>
    </div>
  );
}
