import React from "react";

export default function ProfilePetParent() {
  return (
    <div className="profileGeneral">
      <div className="profileParent">
        <h5 className="semi">Speak with a Pet Parent</h5>
        <p className="sm lh20">
          Chat Directly with a Resident Pet Parent currently living here. Get
          answers to pet-related questions about this business.
        </p>
        <div className="profileParent__inner">
          <div className="profileParent__row">
            <div className="profileParent__row-info">
              <div className="profileParent__row-avatar green">
                <img
                  src={process.env.PUBLIC_URL + "/images/avatar/user.jpg"}
                  alt="user"
                />
              </div>
              <h6>John Doe</h6>
            </div>
            <h6 className="sm semi">Start Conversation</h6>
          </div>
          <div className="profileParent__row">
            <div className="profileParent__row-info">
              <div className="profileParent__row-avatar">
                <img
                  src={process.env.PUBLIC_URL + "/images/avatar/user.jpg"}
                  alt="user"
                />
              </div>
              <h6>John Doe</h6>
            </div>
            <h6 className="sm semi">Start Conversation</h6>
          </div>
          <div className="profileParent__row">
            <div className="profileParent__row-info">
              <div className="profileParent__row-avatar">
                <img
                  src={process.env.PUBLIC_URL + "/images/avatar/user.jpg"}
                  alt="user"
                />
              </div>
              <h6>John Doe</h6>
            </div>
            <h6 className="sm semi">Start Conversation</h6>
          </div>
          <div className="profileParent__row">
            <div className="profileParent__row-info">
              <div className="profileParent__row-avatar">
                <img
                  src={process.env.PUBLIC_URL + "/images/avatar/user.jpg"}
                  alt="user"
                />
              </div>
              <h6>John Doe</h6>
            </div>
            <h6 className="sm semi">Start Conversation</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
