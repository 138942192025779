import React from "react";
import Post from "./components/Posts/Post";
import { posts, users } from "./components/Posts/Moduls";
import Near from "./components/Near";
import Reels from "./components/Reels/Reels";
import Deels from "./components/Deels/Deels";
import Mpp from "./components/Mpp/Mpp";
import Group from "./components/Group/Group";
import Sales from "./components/Sales/Sales";
import News from "./components/News/News";
import OnMind from "./components/OnMind";

export default function Home({ setModal }) {
  return (
    <>
      <div className="feed">
        <OnMind setModal={setModal} />
        <Post user={users.SM} post={posts.post1} />
        <Near />
        <Reels />
        <Post user={users.PQ} post={posts.post7} />
        <Deels />
        <Post user={users.PQ} post={posts.post2} />
        <Mpp />
        <Post user={users.SM} post={posts.post3} />
        <Group />
        <Post user={users.PQ} post={posts.post4} />
        <Post user={users.Priya} post={posts.post5} />
        <Post user={users.QP} post={posts.post8} />
        <Sales user={users.Jack} />
        <Post user={users.AP} post={posts.post6} />
        <News />
      </div>
    </>
  );
}
