import React from "react";

export default function ListingItem(props) {
  return (
    <div className="exploreListingSlider__item">
      <div className="exploreListingSlider__item-image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </div>
      <div className="exploreListingSlider__item-content">
        <h6 className="semi">{props.title}</h6>
        <h6 className="sm">{props.name}</h6>
        <h6 className="sm">{props.detail}</h6>
      </div>
    </div>
  );
}
