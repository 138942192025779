import React, { useEffect, useRef, useState } from "react";
import { chevronBottom } from "./SVG";

export default function SelectPrivacy({ selected = null, list, onChange }) {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);
  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  // useEffect(() => {
  //   if (currentSelected)
  //     setCurrentList(
  //       list.filter((item) => {
  //         let compareKey = key ?? "value";
  //         return item[compareKey] !== currentSelected[compareKey];
  //       })
  //     );
  // }, [currentSelected]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  return (
    <div className={"select " + (active ? "active" : "")} ref={wrapper}>
      <div className="select__selected" onClick={toggleActive}>
        {currentSelected.svg}
        {currentSelected ? currentSelected.value : "-----"}
        {chevronBottom}
      </div>
      <ul className={"select__options " + (active ? "active" : "")}>
        <h3>Choose who your post to visible to</h3>
        {currentList.map((item, index) => (
          <li
            className={
              "select__option " +
              (currentSelected.id === item.id ? "active" : "")
            }
            key={index}
            onClick={() => onClick(item)}
          >
            {item.svg}
            <div className="select__option-inner">
              <h5>{item.value}</h5>
              <p>{item.desc}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
