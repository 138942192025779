import React from "react";
import TextInput from "../../../Base/Form/TextInput";
import DateInput from "../../../Base/Form/DateInput";
import RadioInput from "../../../Base/Form/RadioInput";
import TextArea from "../../../Base/Form/TextArea";
import { chevronLeft } from "../../../Base/SVG";
import { Link } from "react-router-dom";

export default function ProfileGeneral({ isActive, error, form, setForm }) {
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  return (
    <div className={"profileEdit " + (isActive === "general" ? "visible" : "")}>
      <div className="profileEdit__header">
        <Link to="/profile" className="profileEdit__back">
          {chevronLeft}
        </Link>
        <h3>General Information</h3>
        <button type="button" className="save">
          Save
        </button>
      </div>
      <div className="profileEdit__inner">
        <TextInput
          placeholder="Enter Pets Name"
          label="My Pets Name"
          type="text"
          errorText="Please enter your pets name"
          visibility={true}
          error={error}
          value={form.petName}
          onChange={onChangeInput("petName")}
        />
        <TextInput
          placeholder="Enter Pets Type"
          label="Pet Type"
          type="text"
          errorText="Please enter your pets type"
          error={error}
          visibility={true}
          value={form.petType}
          onChange={onChangeInput("petType")}
        />
        <DateInput
          selected={form.petDate}
          visibility={true}
          label="Date of Birth"
          startDate={form.petDate}
          errorText="Please enter your pets DOB"
          placeholder="dd-mm-yyyy "
          onChange={(date) => updateForm({ petDate: date })}
        />
        <RadioInput
          label="Spayed or Neutered"
          option1="Spayed"
          option2="Neutered"
          error={error}
          type="radio"
          name="type"
          visibility={true}
          errorText="Please select an option"
          onChange1={(e) => {
            if (e.target.checked) updateForm({ type: "spayed" });
          }}
          onChange2={(e) => {
            if (e.target.checked) updateForm({ type: "neutered" });
          }}
        />
        <TextArea
          placeholder="Enter Pets Description"
          label="Brief Pet Description"
          errorText="Please enter your pets description"
          error={error}
          visibility={true}
          value={form.petDesc}
          onChange={onChangeInput("petDesc")}
        />
      </div>
    </div>
  );
}
