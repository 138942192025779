import React from "react";

export default function ProfileDesc() {
  return (
    <div className="profileGeneral">
      <div className="profileDesc">
        <h5 className="semi">Description</h5>
        <p className="sm lh20">
          Lörem ipsum hexade rear geolåd mikropp med plav. Trena kvasigt. Deda
          nens dingen medan ultras: faling. Resat don. Spedat näthat biska
          vibins. <br />
          Lörem ipsum hexade rear geolåd mikropp med plav. Trena kvasigt. Deda
          nens dingen medan ultras: faling. Resat don. Spedat näthat biska
          vibins. <br />
          Lörem ipsum hexade rear geolåd mikropp med plav. Trena kvasigt. Deda
          nens dingen medan ultras: faling. Resat don. Spedat näthat biska
          vibins.
        </p>
      </div>
    </div>
  );
}
