import React from "react";
import { Link, NavLink } from "react-router-dom";
import { bell, explore, home, market, messages, plus } from "./SVG";

export default function Nav({ addClass, setModal, addClass2 }) {
  return (
    <nav className={"nav " + addClass + addClass2}>
      <div className="nav__inner">
        <div className="nav__inner-links">
          <NavLink to="/" className="nav__inner-link">
            {home}
            <span> Home</span>
          </NavLink>
          <NavLink to="/explore" className="nav__inner-link uniq">
            {explore}
            <span>Explore</span>
          </NavLink>
          <NavLink to="/marketplace" className="nav__inner-link">
            {market}
            <span>Marketplace</span>
          </NavLink>
          <NavLink to="/notification" className="nav__inner-link">
            {bell}
            <span>Notification</span>
          </NavLink>
          <NavLink to="/messages" className="nav__inner-link remove">
            {messages}
            <span>Messages</span>
          </NavLink>
          <button
            type="button"
            className="button post"
            onClick={() => setModal(true)}
          >
            <span>{plus} </span> Post
          </button>
        </div>
        <div className="nav__inner-copy">
          <p className="sm">
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <p className="sm">
            <Link to="/terms">Terms and Conditions</Link>
          </p>
          <p className="sm">2022 copyright. all rights reserved</p>
        </div>
      </div>
    </nav>
  );
}
