import React from "react";
import { tickIconGreen } from "../../../../Base/SVG";

export default function ProfileAmenities() {
  return (
    <div className="profileGeneral">
      <div className="profileAmenities">
        <h5 className="semi">Amenities</h5>
        <div className="profileAmenities__inner">
          <ul className="profileAmenities__list">
            <li>{tickIconGreen}Laundry facilities</li>
            <li>{tickIconGreen}School nearby</li>
            <li>{tickIconGreen}Parks nearby</li>
            <li>{tickIconGreen}Shopping nearby</li>
            <li>{tickIconGreen}Public transit</li>
            <li>{tickIconGreen}Walk-in closet</li>
            <li>{tickIconGreen}City views</li>
            <li>{tickIconGreen}In-suit storage</li>
          </ul>
          <ul className="profileAmenities__list">
            <li>{tickIconGreen}Balconies</li>
            <li>{tickIconGreen}Stove</li>
            <li>{tickIconGreen}Fridge</li>
            <li>{tickIconGreen}Air conditioner</li>
            <li>{tickIconGreen}Keyless entry</li>
            <li>{tickIconGreen}Surface parking</li>
            <li>{tickIconGreen}Security lights</li>
            <li>{tickIconGreen}Stainless steel options available</li>
          </ul>
          <ul className="profileAmenities__list">
            <li>{tickIconGreen}Laundry facilities</li>
            <li>{tickIconGreen}School nearby</li>
            <li>{tickIconGreen}Parks nearby</li>
            <li>{tickIconGreen}Shopping nearby</li>
            <li>{tickIconGreen}Public transit</li>
            <li>{tickIconGreen}Walk-in closet</li>
            <li>{tickIconGreen}City views</li>
            <li>{tickIconGreen}In-suit storage</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
