import React from "react";
import { listed, yListed } from "../../Base/SVG";
import Cat from "./components/Cat/Cat";
import Listed from "./components/Listed/Listed";
import Sale from "./components/Sale/Sale";
import SaleFree from "./components/SaleFree/SaleFree";

export default function Market() {
  return (
    <div className="market">
      <div className="market__header">
        <div className="tabBtns">
          <button type="button" className="tabBtn active">
            For Sale
          </button>
          <button type="button" className="tabBtn">
            Free
          </button>
          <button type="button" className="tabBtn">
            Categories
          </button>
        </div>
        <div className="tabMores">
          <button type="button" className="tabMore active">
            {listed} List an item
          </button>
          <button type="button" className="tabMore">
            {yListed} Your listings
          </button>
        </div>
      </div>
      <Cat />
      <Listed />
      <Sale />
      <SaleFree />
    </div>
  );
}
