import './PrivacyPolicy.css'
import logo from "../../Assets/logo.svg";

const PrivacyPolicy = () => {
  return (
    <>
        <div className="parent_privacy_policy">
            <div className="text-center">
                <img src={logo} alt="logo" className="logo_width mb-4" />
            </div>
            <h1 className='font-bold text-center'>Privacy Policy</h1>

            <h3 className='mt-5'>Data Collection</h3>
            <p>
            We collect personal information including your name, email, gender and device information. This data is collected through your inputs within the app, as well as technical information provided by the app.
            </p>

            <h3 className='mt-5'>Use Of Data</h3>
            <p>
            We collect this data to personalize our service and provide relevant solar maintenance information from third parties. Your data allows us to offer individualized maintenance recommendations and enhance the user experience through personalized alerts and reminders. The solar performance data you provide is anonymized and shared with Ship2Mates to retrieve advanced maintenance insights. In addition, some data is shared with Google Analytics to help us understand app usage and improve our service.
            </p>

            <h3 className='mt-5'>Security</h3>
            <p>
            We are committed to ensuring the security of your data. We implement best practice IT security measures, including robust identity and access management protocols. Our app is hosted on professional cloud servers, ensuring data safety.
            </p>

            <h3 className='mt-5'>User Rights</h3>
            <p>
            You have the right to view, edit, and delete your data. If you wish to exercise any of these rights, please send an email to <a href="mailto:Hello@Ship2Mates.com">Hello@Ship2Mates.com</a>. Your data will be no longer kept than necessary for the calorie tracking process without your consent. Upon deletion of your account, all your personal data will be deleted.
            </p>

            <h3 className='mt-5'>Contact</h3>
            <p className='pb-5'>
            If you have any concerns regarding your data, please contact at <a href="mailto:Hello@Ship2Mates.com">Hello@Ship2Mates.com</a> 
            </p>
        </div>
    </>
  )
}

export default PrivacyPolicy