export const users = {
  SM: {
    name: "S. M.",
    avatar: "/images/avatar/SAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    date: "2d",
    link: "google.com",
  },
  PQ: {
    name: "P. Q.",
    avatar: "/images/avatar/PAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    link: "google.com",
    date: "2d",
  },
  QP: {
    name: "Q. P.",
    avatar: "/images/avatar/QAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    link: "google.com",
    date: "2d",
  },
  AP: {
    name: "A. P.",
    avatar: "/images/avatar/AAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    link: "google.com",
    date: "2d",
  },
  Priya: {
    name: "Priya A.",
    avatar: "/images/avatar/PAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    link: "google.com",
    date: "2d",
  },
  Jack: {
    name: "Jack P.",
    avatar: "/images/avatar/JAvatar.png",
    location: "Saint-Anotio, Saint-Jerme",
    link: "google.com",
    date: "2d",
  },
};
export const posts = {
  post1: {
    likes: 12,
    more: "google.com",
    text: "Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare",
  },
  post2: {
    likes: 8,
    link: "https://www.facebook.com/fishnmeatplus/photos/a.112094600656491/625645185968094/",
    linkTitle: "Lost cat in Saint-Jerme",
    linkText: "facebook.com",
    linkImage: "/images/postImage.jpg",
    more: "google.com",
  },
  post3: {
    likes: 14,
    link: "https://www.facebook.com/fishnmeatplus/photos/a.112094600656491/625645185968094/",
    linkTitle: "Lost cat in Saint-Jerme",
    linkText: "facebook.com",
    linkImage: "/images/postImage.jpg",
    more: "google.com",
    text: "Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare...",
  },
  post4: {
    likes: 12,
    text: "Let me know how many if you need a pet sitter?",
    question: "Do you need a Pet Sitter?",
  },
  post5: {
    text: "Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare...",
    likes: 12,
    reply: {
      user: users.SM,
    },
  },
  post6: {
    likes: 12,
    text: " Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare Lorem ipsum dolor sit amet consectetur. Eget velit mauris convallis semper turpis et turpis aliquam. Sollicitudin pretium sed eu ipsum mi.",
    image: "/images/postImage.jpg",
  },
  post7: {
    likes: 12,
    text: "Lörem ipsum plafyrar trenåbel nesunde om stenoliga. Soskapet sangar i vavis dihet. Var. Mellanförskap konisk jåna. Suprast ira plare Lorem ipsum dolor sit amet consectetur. Eget velit mauris convallis semper turpis et turpis aliquam. Sollicitudin pretium sed eu ipsum mi.",
    images: [
      "/images/slider/slider-1.jpg",
      "/images/slider/slider-2.jpg",
      "/images/slider/slider-3.jpg",
    ],
  },
  post8: {
    likes: 16,
    phone: "Added a phone number.",
    linkTitle: "Lost cat in Saint-Jerme",
    linkText: "facebook.com",
    linkImage: "/images/icons/house.png",
    more: "google.com",
  },
};
