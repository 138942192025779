import React from "react";
import { message, messages } from "../SVG";

export default function Messages() {
  return (
    <div className="messages">
      <div className="messages__btn">{messages}</div>
    </div>
  );
}
