import React, { useEffect, useState } from "react";
import { closeIcon } from "../../SVG";

export default function ImageList({ imgList, form, updateForm }) {
  const deleteImage = (index) => {
    let images = [...form.images];
    images.splice(index, 1);
    updateForm({ images: images });
  };

  return (
    <div className="modalContent__gallery">
      {imgList.map((file, index) => (
        <ImgItem
          key={index}
          file={file}
          deleteImage={() => deleteImage(index)}
        />
      ))}
    </div>
  );
}

const ImgItem = ({ file, deleteImage }) => {
  const [image, setImage] = useState("default.png");

  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (file) {
      readFile({
        file: file,
        onLoad: (result) => setImage(result),
      });
    }
  }, [file]);

  return (
    <div className="modalContent__gallery-item">
      <div className="modalContent__gallery-close" onClick={deleteImage}>
        {closeIcon}
      </div>
      <div className="modalContent__gallery-image">
        <img src={image} />
      </div>
    </div>
  );
};
