import React, { useEffect, useRef, useState } from "react";
import { camera, chevronLeft } from "../../../../Base/SVG";
import { Link } from "react-router-dom";

export default function ProfileBanner({ form, setForm, isActive }) {
  const fileRef = useRef(null);
  const [image, setImage] = useState("");
  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };
  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      readFile({
        file: fileRef.current.files[0],
        onLoad: (result) => setImage(result),
      });
    }
  };
  useEffect(() => {
    setForm({ ...form, banner: image });
  }, [image]);

  return (
    <div
      className={"profileEdit " + (isActive === "coverPhoto" ? "visible" : "")}
    >
      <div className="profileEdit__header">
        <Link to="/profile" className="profileEdit__back">
          {chevronLeft}
        </Link>
        <h3>Cover Photo</h3>
      </div>
      <div className="profileBan">
        <div className={"profileBan__image " + (image !== "" ? "active" : "")}>
          {image === "" ? "" : <img src={image} />}
          {camera}
        </div>
        <h4>
          Your Cover Photo will be user to customize the header of your profile.
        </h4>
        <h6 className="sm">
          For best results, upload an image that is 1300px by 225px or larger.
        </h6>
        <div className="profileBan__btns">
          <button
            type="button"
            className="formPhoto delete"
            onClick={() => setImage("")}
          >
            Delete Photo
          </button>
          <button type="button" className="formPhoto upload">
            <input
              type="file"
              accept="image/png , image/jpeg"
              ref={fileRef}
              onChange={previewImage}
            />
            Upload Photo
          </button>
        </div>
      </div>
    </div>
  );
}
