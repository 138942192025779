import React, { useState } from "react";
import DotMore from "../../../Base/Widgets/DotMore";
import { Link } from "react-router-dom";
import { chevronLeft, divider } from "../../../Base/SVG";
import ProfileMenu from "./ProfileMenu";
export default function Profile() {
  const [profileMenu, setProfileMenu] = useState(false);
  const [menuType, setMenuType] = useState("owner");

  return (
    <div className="profile">
      <div className="profile__inner">
        <div className="profileBanner">
          <Link to="/" className="profile__mobB">
            {chevronLeft} <h3> Profile</h3>
          </Link>
          <div className="profileBanner__image">
            <img
              src={process.env.PUBLIC_URL + "/images/postImage.jpg"}
              alt="banner"
            />
          </div>
          <Link to="/edit-profile" className="button connect dark">
            Edit Profile
          </Link>
          <DotMore />
        </div>
        <div className="profileInfo">
          <div className="profileInfo__avatar">
            <img
              src={process.env.PUBLIC_URL + "/images/avatar/user.jpg"}
              alt="user"
            />
          </div>
          <h4>Wilbur</h4>
          <div className="profileInfo__follows">
            <div className="profileInfo__follow">
              <h5>118</h5>
              <h6 className="sm">Followers</h6>
            </div>
            <div className="profileInfo__follow">
              <h5>10</h5>
              <h6 className="sm">Following</h6>
            </div>
          </div>
          <div className="profileInfo__more">
            <h6 className="sm">
              <Link to="">@wilbur</Link>
              {divider}
              <span>Joined Apr 18, 2020</span>
            </h6>
          </div>
        </div>
        <div className="profileGeneral">
          <div className="profileGeneral__inner">
            <h5 className="semi title">Profile Information</h5>
            <div className="profileGeneral__item">
              <h6 className="sm">Username</h6>
              <h5 className="semi">wilbur</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Firstname</h6>
              <h5 className="semi">John</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Lastname</h6>
              <h5 className="semi">Doe</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Profile Type</h6>
              <h5 className="semi">Pet Parent</h5>
            </div>
          </div>
        </div>
        <div className="profileGeneral">
          <div className="profileGeneral__inner">
            <h5 className="semi title">Contact Information</h5>
            <div className="profileGeneral__item">
              <h6 className="sm">City</h6>
              <h5 className="semi">Toronto</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Country</h6>
              <h5 className="semi">Canada</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Zip</h6>
              <h5 className="semi">100758</h5>
            </div>
            <div className="profileGeneral__item">
              <h6 className="sm">Phone</h6>
              <h5 className="semi">4476892736</h5>
            </div>
          </div>
        </div>
      </div>
      {profileMenu && (
        <ProfileMenu
          menuType={menuType}
          setProfileMenu={setProfileMenu}
        />
      )}
      <button
        type="button"
        className="profileMenu__btn"
        onClick={() => setProfileMenu(true)}
      >
        <span></span>
      </button>
    </div>
  );
}
