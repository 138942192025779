import React, { useEffect, useRef, useState } from "react";
import DateInput from "../../Form/DateInput";
import TextArea from "../../Form/TextArea";
import TextInput from "../../Form/TextInput";
import { arrowLeft, closeIcon, imageIcon, locationIcon } from "../../SVG";

export default function ModalEvent({
  setModal,
  setExpand,
  setModalName,
  pEvent,
  setEvent,
  setCallLink,
  callLink,
  setInput,
  form,
  setForm,
  setDiscard,
  initForm,
}) {
  const fileRef = useRef(null);
  const btnClearLocation = useRef(null);
  const [error, setError] = useState(false);
  const [coverImage, setCoverImage] = useState(pEvent.coverImage);
  const updateEvent = (data) => {
    setEvent({ ...pEvent, ...data });
  };
  const setEventInput = (key) => (event) =>
    updateEvent({ [key]: event.target.value });
  ////////////////////// cover image start
  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };

  const previewImage = () => {
    setExpand(true);
    if (fileRef.current.files && fileRef.current.files[0]) {
      readFile({
        file: fileRef.current.files[0],
        onLoad: (result) => setCoverImage(result),
      });
    }
  };
  useEffect(() => {
    setEvent({ ...pEvent, coverImage: coverImage });
  }, [coverImage]);
  ////////////////////// cover image end
  const clearLocation = () => {
    setForm({
      ...form,
      location: "",
    });
  };
  const clickFunc = (e) => {
    if (e.target !== btnClearLocation.current) {
      setModal("map");
      setModalName("event");
    } else clearLocation();
  };
  const btnValidation = () => {
    const isValidVideo =
      pEvent.videoCallLink &&
      pEvent.videoCallLink !== "" &&
      (pEvent.videoCallLink.startsWith("https:/") ||
        pEvent.videoCallLink.startsWith("http:/"));

    if (
      pEvent.name !== "" &&
      ((form.location && form.location !== "") || isValidVideo)
    ) {
      setExpand(true);
      setModal("main");
    } else setError(true);
  };
  return (
    <div className="modal__inner poll">
      <div
        className="modal__inner-back"
        onClick={() => {
          if (
            JSON.stringify(form.pEvent) === JSON.stringify(initForm.pEvent) &&
            form.mind === "" &&
            form.location === ""
          ) {
            setModal("main");
          } else {
            setDiscard(true);
          }
        }}
      >
        {arrowLeft}
      </div>
      <div className="modal__inner-title">
        <h3>Create an Event</h3>
        <button className="button post sm" onClick={btnValidation}>
          Done
        </button>
      </div>
      <div className="modalEvent">
        <div className="modalEvent__cover">
          <div className="modalEvent__cover-image">
            <img
              src={
                coverImage
                  ? coverImage
                  : process.env.PUBLIC_URL + "/images/modal/eventCover.png"
              }
              alt="coverImage"
            />
          </div>
          <div className="modalEvent__cover-btn">
            {imageIcon}
            Cover photo
            <input
              type="file"
              accept="image/png , image/jpeg"
              ref={fileRef}
              onChange={previewImage}
            />
          </div>
        </div>
        <div className="modalEvent__title">
          <TextInput
            value={pEvent.name}
            placeholder="Event name"
            onChange={setEventInput("name")}
            errorText="Please enter a name for the event"
            error={error}
          />
        </div>
        <div className="modalEvent__date">
          <div className="modalEvent__date-row">
            <p>Start</p>
            <div className="modalEvent__date-input">
              <span className={pEvent.startDate !== null ? "top" : "center"}>
                Date
              </span>
              <DateInput
                minDate={new Date()}
                selected={pEvent.startDate}
                startDate={pEvent.startDate}
                onChange={(date) => updateEvent({ startDate: date })}
              />
            </div>
            <div className="modalEvent__date-input">
              <span className={pEvent.startTime !== null ? "top" : "center"}>
                Time
              </span>
              <DateInput
                selected={pEvent.startTime}
                timeIntervals={15}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                dateFormat="h:mm aa"
                onChange={(date) => updateEvent({ startTime: date })}
              />
            </div>
          </div>
          <div className="modalEvent__date-row">
            <p>End (optional)</p>
            <div className="modalEvent__date-input">
              <span className={pEvent.endDate !== null ? "top" : "center"}>
                Date
              </span>
              <DateInput
                minDate={new Date()}
                selected={pEvent.endDate}
                startDate={pEvent.endDate}
                onChange={(date) => updateEvent({ endDate: date })}
              />
            </div>
            <div className="modalEvent__date-input">
              <span className={pEvent.endTime !== null ? "top" : "center"}>
                Time
              </span>
              <DateInput
                selected={pEvent.endTime}
                timeIntervals={15}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                dateFormat="h:mm aa"
                onChange={(date) => updateEvent({ endTime: date })}
              />
            </div>
          </div>
        </div>
        <div className="modalEvent__location" onClick={clickFunc}>
          <span className={form.location !== "" ? "top" : "center"}>
            Location
          </span>
          <div className="modalEvent__location-input">
            <div className="modalEvent__location-icon-location">
              {locationIcon}
            </div>
            <div
              className="modalEvent__location-icon-close"
              ref={btnClearLocation}
            >
              {closeIcon}
            </div>
            <TextInput
              type="text"
              placeholder=""
              value={form.location}
              errorText="Please enter either a location or a video call link"
              error={pEvent.videoCallLink !== "" ? false : error}
              onChange={setInput("location")}
            />
          </div>
        </div>
        <div className="modalEvent__link">
          {callLink ? (
            <div className="modalEvent__link-inner">
              <span className={pEvent.videoCallLink !== "" ? "top" : "center"}>
                Video call link
              </span>
              <div className="modalEvent__link-input">
                <TextInput
                  type="text"
                  link={true}
                  placeholder=""
                  errorText="Event video call link is not a valid url"
                  error={error}
                  value={pEvent.videoCallLink}
                  onChange={setEventInput("videoCallLink")}
                />
              </div>
            </div>
          ) : (
            <>
              <h5 className="semi" onClick={() => setCallLink(true)}>
                Add video call link
              </h5>
            </>
          )}
        </div>
        <div className="modalEvent__detail">
          <h3>Add more details (optional)</h3>
          <TextArea
            value={form.mind}
            placeholder="Description"
            onChange={setInput("mind")}
          />
        </div>
      </div>
    </div>
  );
}
