import React from "react";
import Visibility from "./Visibility";

export default function EmailInput(props) {
  return (
    <div className="input__outer">
      <div className="input__header">
        {props.label && <label>{props.label}</label>}
        {props.visibility && <Visibility />}
      </div>
      <div className="input">
        <input
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
        />
      </div>
      {props.error && props.value === "" && (
        <div className="input__error">{props.errorText}</div>
      )}
    </div>
  );
}
