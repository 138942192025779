import React from "react";
import { Link } from "react-router-dom";
import { circle } from "../../../../Base/SVG";

export default function ListedItem(props) {
  return (
    <Link to={props.link} className="listedItem">
      <div className="listedItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="cats" />
      </div>
      <div className="listedItem__content">
        <h4>{props.title}</h4>
        <h6>
          {props.price} {circle} {props.location}
        </h6>
      </div>
    </Link>
  );
}
