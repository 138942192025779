import React from "react";
import { arrowLeft, locationIcon } from "../../SVG";

export default function ModalMap({ setModal, modalName, onClick }) {
  return (
    <div className="modal__inner map">
      <div className="modal__inner-back" onClick={() => setModal(modalName)}>
        {arrowLeft}
      </div>
      <div className="modal__inner-title">
        <h3>Move map to pin location</h3>
      </div>
      <div className="modalMap">
        <div className="modalMap__btn" onClick={onClick}>
          {locationIcon}
          <h5 className="semi">Use this location</h5>
        </div>
        <div className="modalMap__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.3837130424486!2d-106.87690998404983!3d52.83347052070267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53041f0a6a6707d5%3A0x3005d58c2ab7bb2a!2sHorner%20Ave%2C%20Blaine%20Lake%2C%20SK%20S0J%200J0!5e0!3m2!1sru!2sca!4v1674230584344!5m2!1sru!2sca"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
