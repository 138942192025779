import React from "react";
import { clockIcon2 } from "../../../../Base/SVG";

export default function ProfileBusinessHour() {
  return (
    <div className="profileGeneral">
      <div className="profileHour">
        <h5 className="semi">Business Hour</h5>
        <div className="profileHour__inner">
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Monday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Tuesday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Wednesday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Thursday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Friday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Saturday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
          <div className="profileHour__row">
            <div className="profileHour__row-day">
              {clockIcon2} <h6 className="med">Sunday</h6>
            </div>
            <h6 className="med">8:00 - 20:00</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
