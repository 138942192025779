import React, { useEffect, useState } from "react";
import ProfilePhoto from "./ProfilePhoto/ProfilePhoto";
import ProfileBanner from "./ProfileBanner/ProfileBanner";
import { chevronLeft } from "../../../Base/SVG";
import { Link, useLocation } from "react-router-dom";
import ProfileGeneral from "./ProfileGeneral";
import ProfileContact from "./ProfileContact";

export default function EditProfile() {
  let location = useLocation();
  const isActive = location.state ? location.state?.tab : "profilePhoto";
  const [error, setError] = useState(true);
  const [form, setForm] = useState({
    petName: "",
    petType: "",
    petDate: "",
    petDesc: "",
    phone: "",
    email: "",
    website: "",
    city: "",
    country: "",
    type: "",
    profile: "",
    banner: "",
  });
  useEffect(() => {
    setError(
      form.petName === "" ||
        form.petType === "" ||
        form.petDate === "" ||
        form.petDesc === "" ||
        form.phone === "" ||
        form.email === "" ||
        form.website === "" ||
        form.city === "" ||
        form.country === "" ||
        form.type === "" ||
        form.profile === "" ||
        form.banner === ""
    );
  }, [form]);

  return (
    <div className="profile">
      <div className="profile__inner">
        <div className="profileBack">
          <Link to="/profile"> {chevronLeft}</Link>
          <h3>Edit Profile</h3>
        </div>
        <ProfilePhoto setForm={setForm} form={form} isActive={isActive} />
        <ProfileBanner setForm={setForm} form={form} isActive={isActive} />
        <ProfileGeneral
          isActive={isActive}
          setForm={setForm}
          form={form}
          error={error}
        />
        <ProfileContact
          isActive={isActive}
          setForm={setForm}
          form={form}
          error={error}
        />
      </div>
    </div>
  );
}
