import React from "react";
import Slider from "react-slick";
import GroupItem from "./GroupItem";
const group = [
  {
    image: "/images/group/group-1.jpg",
    title: "Revera Pet House",
    link: "google.com",
  },
  {
    image: "/images/group/group-2.jpg",
    title: "Revera Pet Walkers",
    link: "google.com",
  },
  {
    image: "/images/group/group-1.jpg",
    title: "Revera Pet House",
    link: "google.com",
  },
  {
    image: "/images/group/group-2.jpg",
    title: "Revera Pet House",
    link: "google.com",
  },
];
export default function Group() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2.6,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          arrows: false,
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div className="feed__box">
      <div className="group">
        <div className="group__header">
          <h3>Groups near you</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <div className="group__body">
          <Slider {...settings}>
            {group.map((item, index) => {
              return <GroupItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
