import React from "react";
import SaleFreeItem from "./SaleFreeItem";
import Slider from "react-slick";
const saleFrees = [
  {
    id: "1",
    image: "/images/saleFree/1 (1).jpg",
    link: "/",
    title: "Pet Grooming",
    text: "Find pet grooming nearby",
  },
  {
    id: "2",
    image: "/images/saleFree/1 (2).jpg",
    link: "/",
    text: "Find pet grooming nearby",
    title: "Pet food",
  },
  {
    id: "3",
    image: "/images/saleFree/1 (2).jpg",
    link: "/",
    title: "Pet food",
    text: "Find pet grooming nearby",
  },
];
export default function SaleFree() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.6,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div className="saleFree">
      <div className="marketTitle">
        <h2>For Sale & Free</h2>
      </div>
      <div className="saleFree__slider">
        <Slider {...settings}>
          {saleFrees.map((item, index) => {
            return <SaleFreeItem key={index} {...item} />;
          })}
        </Slider>
      </div>
    </div>
  );
}
