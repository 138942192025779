import React from "react";
import CatItem from "./CatItem";
const categories = [
  {
    id: "1",
    image: "/images/categories/1 (1).jpg",
    link: "/",
    title: "Toys",
  },
  {
    id: "2",
    image: "/images/categories/1 (2).jpg",
    link: "/",
    title: "Beds",
  },
  {
    id: "3",
    image: "/images/categories/1 (3).jpg",
    link: "/",
    title: "Food",
  },
  {
    id: "4",
    image: "/images/categories/1 (4).jpg",
    link: "/",
    title: "Leashes",
  },
  {
    id: "5",
    image: "/images/categories/1 (5).jpg",
    link: "/",
    title: "Hygiene",
  },
  {
    id: "6",
    image: "/images/categories/1 (6).jpg",
    link: "/",
    title: "Grooming",
  },
  {
    id: "7",
    image: "/images/categories/1 (7).jpg",
    link: "/",
    title: "Clothing",
  },
  {
    id: "8",
    image: "/images/categories/1 (8).jpg",
    link: "/",
    title: "Accessory",
  },
];
export default function Cat() {
  return (
    <div className="cat">
      <div className="marketTitle">
        <h2>Popular Categories</h2>
        <a href="#" className="link">
          See all
        </a>
      </div>
      <div className="cat__row">
        {categories.map((item, index) => {
          return <CatItem key={index} {...item} />;
        })}
      </div>
    </div>
  );
}
