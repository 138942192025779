import React from "react";
import Notification from "./Widgets/Notification";
import Avatar from "./Avatar";
import { filterIcon, mHeader, search } from "./SVG";
import { Link } from "react-router-dom";

export default function Header({
  addClass,
  filterBtn,
  exploreFilter,
  setExploreFilter,
}) {
  return (
    <>
      <header className={"header " + (filterBtn ? "bxsh " : "") + addClass}>
        <div className="auto__container">
          <div className="header__inner">
            <Link to="" className="header__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </Link>
            <div className="search">
              <input type="text" placeholder="Search anything pet-friendly" />
              <button type="button">{search}</button>
            </div>
            <div className="header__inner-row">
              <Notification />
              <Avatar />
            </div>
            {filterBtn ? (
              <>
                <div className="mess">
                  <div
                    className="mess__btn"
                    onClick={() => setExploreFilter(!exploreFilter)}
                  >
                    {filterIcon}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mess">
                  <div className="mess__btn">{mHeader}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
