import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  bell,
  buss,
  dataProtection,
  group,
  headphone,
  membAgreement,
  messages,
  pet,
  plusSolid,
  privacyPolicy,
  shareIconEmpty,
  supportIcon,
} from "./SVG";

export default function Sidebar({ sidebarAdd }) {
  return (
    <div className="sidebar">
      <div className="sidebar__inner">
        <ul className="sidebar__inner-links">
          <li>
            <NavLink to="/your-business" className="sidebar__inner-link title">
              {pet}
              Your PetsProfile
            </NavLink>
          </li>
          <li>
            <NavLink to="/id" className="sidebar__inner-link">
              <img
                src={process.env.PUBLIC_URL + "/images/postImage.jpg"}
                alt="postImage"
              />
              Jerico - Cat
            </NavLink>
            <ul>
              <li>
                <NavLink to="/notification" className="sidebar__inner-link">
                  {bell}
                  Notification
                </NavLink>
              </li>
              <li>
                <NavLink to="/messages" className="sidebar__inner-link">
                  {messages}
                  Messages
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/sell" className="sidebar__inner-link">
              See all Businesses
            </NavLink>
          </li>
          <li>
            <Link to="/add-pet" className="sidebar__inner-link uniq">
              {plusSolid}
              Add new Pet
            </Link>
          </li>
        </ul>
        <ul className="sidebar__inner-links">
          <li>
            <NavLink to="/your-business" className="sidebar__inner-link title">
              {buss}
              Your Businesses
            </NavLink>
          </li>
          <li>
            <NavLink to="/id" className="sidebar__inner-link">
              <img
                src={process.env.PUBLIC_URL + "/images/postImage.jpg"}
                alt="postImage"
              />
              Toronto Pet Shop
            </NavLink>
            <ul>
              <li>
                <NavLink to="/notification" className="sidebar__inner-link">
                  {bell}
                  Notification
                </NavLink>
              </li>
              <li>
                <NavLink to="/messages" className="sidebar__inner-link">
                  {messages}
                  Messages
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/sell" className="sidebar__inner-link">
              See all Businesses
            </NavLink>
          </li>
          <li>
            <Link to="/add-business" className="sidebar__inner-link uniq">
              {plusSolid}
              Add new Business
            </Link>
          </li>
        </ul>
        <ul className="sidebar__inner-links">
          <li>
            <NavLink to="/your-groups" className="sidebar__inner-link title">
              {group}
              Your Groups
            </NavLink>
          </li>
          <li>
            <NavLink to="/id" className="sidebar__inner-link">
              <img
                src={process.env.PUBLIC_URL + "/images/postImage.jpg"}
                alt="postImage"
              />
              Super Pet Walkers
            </NavLink>
            <ul>
              <li>
                <NavLink to="/notification" className="sidebar__inner-link">
                  {bell}
                  Notification
                </NavLink>
              </li>
              <li>
                <NavLink to="/messages" className="sidebar__inner-link">
                  {messages}
                  Messages
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/sell" className="sidebar__inner-link">
              See all Groups
            </NavLink>
          </li>
          <li>
            <Link to="/" className="sidebar__inner-link uniq">
              {plusSolid}
              Add new Group
            </Link>
          </li>
        </ul>
        {sidebarAdd && (
          <>
            <ul className="sidebar__inner-links">
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {supportIcon}
                  Support
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {headphone}
                  Help Center
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {privacyPolicy}
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {membAgreement}
                  Member Agreement
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {shareIconEmpty}
                  Share this App
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/your-groups"
                  className="sidebar__inner-link title"
                >
                  {dataProtection}
                  Data Protection
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
