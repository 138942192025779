import React, { useEffect, useState } from "react";
import TextInput from "../../../Base/Form/TextInput";
import { chevronLeft } from "../../../Base/SVG";
import { Link } from "react-router-dom";

const listing = [
  {
    id: "1",
    title: "Pet-friendly Business",
    isActive: false,
    image: "/images/listing/1 (1).jpg",
  },
  {
    id: "2",
    title: "Pet-friendly Housing",
    isActive: true,
    image: "/images/listing/1 (2).jpg",
  },
  {
    id: "3",
    isActive: false,
    title: "Pet-friendly Event",
    image: "/images/listing/1 (3).jpg",
  },
  {
    id: "4",
    isActive: false,
    title: "Pet-friendly Service",
    image: "/images/listing/1 (4).jpg",
  },
  {
    id: "5",
    isActive: false,
    title: "Pet Product",
    image: "/images/listing/1 (5).jpg",
  },
  {
    id: "6",
    isActive: false,
    title: "Pet-friendly Job",
    image: "/images/listing/1 (6).jpg",
  },
];
export default function AddBusiness() {
  const [error, setError] = useState(true);

  const [form, setForm] = useState({
    busName: "",
    category: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  useEffect(() => {
    setError(form.petName === "" || form.petType === "" || form.petDate === "");
  }, [form]);

  return (
    <div className="profile buss">
      <div className="profile__inner">
        <div className="profileBack">
          <Link to="/profile"> {chevronLeft}</Link>
          <h3>Add New Business or Listing</h3>
        </div>
        <div className="profileEdit">
          <div className="profileEdit__header">
            <Link to="/profile" className="profileEdit__back">
              {chevronLeft}
            </Link>
            <h3>Add New Business or Listing</h3>
          </div>
          <div className="profileEdit__inner">
            <div className="profileList">
              <h3>Listing Type</h3>
              <h6 className="sm">Select a listing type to proceed</h6>
              <div className="profileList__row">
                {listing.map((item, index) => {
                  return (
                    <div
                      className={
                        "profileList__item " + (item.isActive ? "active" : "")
                      }
                      key={index}
                    >
                      <div className="profileList__item-image">
                        <img
                          src={process.env.PUBLIC_URL + item.image}
                          alt="listing"
                        />
                      </div>
                      <div className="profileList__item-content">
                        <h3>{item.title}</h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="profileEdit">
          <div className="profileEdit__inner">
            <TextInput
              placeholder="Enter Business Name"
              label="Name/Title"
              type="text"
              errorText="Please enter your business name"
              visibility={true}
              error={error}
              value={form.busName}
              onChange={onChangeInput("busName")}
            />
            <TextInput
              placeholder="Enter Category"
              label="Category"
              type="text"
              errorText="Please select your pet type"
              error={error}
              visibility={true}
              value={form.category}
              onChange={onChangeInput("category")}
            />
            <div className="profileEdit__footer">
              <button type="button" className="formBtn clear">
                CLEAR
              </button>
              <button type="button" className="formBtn submit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
