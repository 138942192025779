import React, { useRef } from "react";
import Visibility from "./Visibility";

export default function TextInput(props) {
  const inpRef = useRef(null);
  return (
    <div className="input__outer">
      <div className="input__header">
        {props.label && <label>{props.label}</label>}
        {props.visibility && <Visibility />}
      </div>
      <div className={"input " + (props.icon ? "wicon" : "")}>
        <input
          ref={inpRef}
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
        />
        {props.icon && <div className="input__icon">{props.icon}</div>}
        {props.choiceLength > 2 && (
          <div
            className="input__icon"
            onClick={() => {
              props.closeIconHandler();
            }}
          >
            {props.svg}
          </div>
        )}
      </div>
      {props.link
        ? props.error &&
          !props.value.startsWith("https:/") &&
          !props.value.startsWith("http:/") &&
          props.value !== "" && (
            <div className="input__error">{props.errorText}</div>
          )
        : props.error &&
          props.value === "" && (
            <div className="input__error">{props.errorText}</div>
          )}
    </div>
  );
}
