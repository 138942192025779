import React from "react";

export default function OnMind({ setModal }) {
  return (
    <div className="feed__box">
      <div className="onmind" onClick={() => setModal(true)}>
        <div className="onmind__image">
          <img
            src={process.env.PUBLIC_URL + "/images/avatar/avatar.png"}
            alt="avatar"
          />
        </div>
        <div className="onmind__inner">
          <p>What's on your mind, neighbor?</p>
        </div>
      </div>
    </div>
  );
}
