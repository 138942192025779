import React, { useEffect, useState } from "react";
import RadioInputExplore from "../../../Base/Form/RadioInputExplore";
import SelectInput from "../../../Base/Form/SelectInput";
import TextInput from "../../../Base/Form/TextInput";
import { chevronLeft, locationIconEmpty } from "../../../Base/SVG";

const initForm = {
  option: "",
  title: "",
  address: "",
  radius: "",
  category: "",
  minPrice: "",
  maxPrice: "",
  apartmentUnit: "",
  availability: "",
};
const selectList = [
  {
    id: "1",
    value: "Business",
  },
  {
    id: "2",
    value: "Apartment",
  },
];
export default function ExploreFilter({ setExploreFilter, exploreFilter }) {
  const [form, setForm] = useState(initForm);
  const [select, setSelect] = useState(1);
  const [error, setError] = useState(true);

  useEffect(() => {
    if (select === "Apartment") {
      setError(
        form.option === "" ||
          form.title === "" ||
          form.address === "" ||
          form.radius === "" ||
          form.category === "" ||
          form.minPrice === "" ||
          form.maxPrice === "" ||
          form.apartmentUnit === ""
      );
    } else if (select === "Business") {
      setError(
        form.option === "" ||
          form.title === "" ||
          form.address === "" ||
          form.radius === "" ||
          form.category === "" ||
          form.minPrice === "" ||
          form.maxPrice === "" ||
          form.availability === ""
      );
    } else {
      setError(
        form.option === "" ||
          form.title === "" ||
          form.address === "" ||
          form.radius === "" ||
          form.category === "" ||
          form.minPrice === "" ||
          form.maxPrice === ""
      );
    }
  }, [select, form]);
  const changeMedium = (item) => {
    setSelect(item.value);
    updateForm({ option: item.value });
  };
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  return (
    <div className="explore__filter">
      <div className="explore__filter-title">
        <div
          className="explore__filter-title-back"
          onClick={() => setExploreFilter(!exploreFilter)}
        >
          {chevronLeft} Back
        </div>
        <div
          className="explore__filter-title-apply"
          onClick={() => setExploreFilter(!exploreFilter)}
        >
          Apply
        </div>
      </div>
      <div className="explore__filter-inner">
        <SelectInput
          value={form.option}
          error={error}
          errorText="Please select the option you want to search"
          list={selectList}
          selected={selectList[0]}
          onChange={changeMedium}
        />
        <TextInput
          type="text"
          value={form.title}
          placeholder="What are you looking for?"
          onChange={setInput("title")}
          error={error}
          errorText="Please enter the title"
        />
        <TextInput
          type="text"
          value={form.address}
          placeholder="Search by address"
          onChange={setInput("address")}
          icon={locationIconEmpty}
          error={error}
          errorText="Please enter your address to search the area"
        />
        <TextInput
          type="number"
          value={form.radius}
          placeholder="20"
          onChange={setInput("radius")}
          error={error}
          errorText="Please enter the radius; by miles"
        />
        <TextInput
          type="text"
          value={form.category}
          placeholder="Apartment Buildings (Pet-friendly)"
          onChange={setInput("category")}
          error={error}
          errorText="Search by category"
        />

        <div className="explore__filter-row">
          <TextInput
            type="number"
            value={form.minPrice}
            placeholder="1"
            onChange={setInput("minPrice")}
            error={error}
            errorText="Min Price"
          />
          <TextInput
            type="number"
            value={form.maxPrice}
            placeholder="99"
            onChange={setInput("maxPrice")}
            error={error}
            errorText="Max Price"
          />
        </div>
        {select === "Apartment" && (
          <RadioInputExplore
            option1="Studio"
            option2="Loft"
            option3="One Bed"
            option4="Two Bed"
            option5="Duplex"
            error={error}
            type="radio"
            name="type"
            value={form.apartmentUnit}
            errorText="Type of unit available"
            onChange1={(e) => {
              if (e.target.checked) updateForm({ apartmentUnit: "Studio" });
            }}
            onChange2={(e) => {
              if (e.target.checked) updateForm({ apartmentUnit: "Loft" });
            }}
            onChange3={(e) => {
              if (e.target.checked) updateForm({ apartmentUnit: "One Bed" });
            }}
            onChange4={(e) => {
              if (e.target.checked) updateForm({ apartmentUnit: "Two Bed" });
            }}
            onChange5={(e) => {
              if (e.target.checked) updateForm({ apartmentUnit: "Duplex" });
            }}
          />
        )}
        {select === "Business" && (
          <TextInput
            type="text"
            value={form.availability}
            placeholder="Open now"
            onChange={setInput("availability")}
            error={error}
            errorText="Search by availability"
          />
        )}
        <button
          className="button post"
          onClick={() => setExploreFilter(!exploreFilter)}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
