import React from "react";
import { dots } from "../../../../Base/SVG";
import { Link } from "react-router-dom";

export default function ReelItem(props) {
  return (
    <div className="reelItem">
      <Link to="/detail" className="reelItem__link"></Link>
      <button type="button" className="reelItem__dots">
        {dots}
      </button>
      <div className="reelItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="reels" />
      </div>
      <div className="reelItem__inner">
        <h6 className="semi">{props.title}</h6>
        <h6 className="semi">{props.views} views</h6>
      </div>
    </div>
  );
}
