import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { box, dotsMore, help, listed, lock, switchIco, user } from "../SVG";

export default function DotMore() {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  const clickListener = React.useCallback((e) => {
    const className = e.target.classList[0];
    if (!className || !className.startsWith("account")) setActive(false);
    if (className === "account__list-link") setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      setTimeout(() => document.addEventListener("click", clickListener), 100);
    } else document.removeEventListener("click", clickListener);
    return () => document.removeEventListener("click", clickListener);
  }, [active]);
  return (
    <div className="dotMore" ref={wrapper}>
      <button type="button" className="dotMore__btn" onClick={toggleActive}>
        {dotsMore}
      </button>
      <div className={`dropdown  ${active ? "active" : ""} `}>
        <Link to="/edit-profile" className="dropdown__link">
          {listed}
          Edit Account
        </Link>
        <Link to="/" className="dropdown__link">
          {user} Account Settings
        </Link>
        <Link to="/" className="dropdown__link">
          {lock} Privacy Settings
        </Link>
        <Link to="/" className="dropdown__link">
          {help} Notifications
        </Link>
        <Link to="/" className="dropdown__link">
          {switchIco} Feed Preference
        </Link>
        <Link to="/" className="dropdown__link">
          {box} Notifications
        </Link>
      </div>
    </div>
  );
}
