import React from "react";

export default function GroupItem(props) {
  return (
    <div className="groupItem">
      <div className="groupItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="group" />
      </div>
      <div className="groupItem__inner">
        <h6 className="semi">{props.title}</h6>
        <a href={props.link} className="button join dark">
          Join
        </a>
      </div>
    </div>
  );
}
