import React from "react";
import { closeIcon } from "../../SVG";
import TextArea from "../../Form/TextArea";
import TextInput from "../../Form/TextInput";

export default function ChoiceList({ poll, setPoll }) {
  const updatePoll = (data) => {
    setPoll({ ...poll, ...data });
  };

  const setChoice = (index) => (e) => {
    let choice = [...poll.choice];
    choice[index] = e.target.value;

    updatePoll({ choice: choice });
  };
  const deleteChoice = (index) => {
    if (poll.choice.length > 2) {
      let choice = [...poll.choice];
      choice.splice(index, 1);
      updatePoll({ choice: choice });
    }
  };
  const addChoice = () => {
    if (poll.choice.length < 6) {
      let choice = [...poll.choice, ""];
      updatePoll({ choice: choice });
    }
  };

  return (
    <div className="modalPoll">
      <TextArea
        value={poll.question}
        onChange={(e) => updatePoll({ question: e.target.value })}
        placeholder="Ask a question"
      />
      <h5>Add poll choices</h5>

      {poll.choice.map((item, index) => {
        return (
          <TextInput
            key={index}
            value={item}
            onChange={setChoice(index)}
            placeholder={"Choice " + (index + 1)}
            closeIconHandler={() => deleteChoice(index)}
            svg={closeIcon}
            choiceLength={poll.choice.length}
          />
        );
      })}
      <button className="button choice" onClick={() => addChoice()}>
        Add choice
      </button>
    </div>
  );
}
