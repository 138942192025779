import React from "react";

export default function ProfileSpokesPerson({ text }) {
  return (
    <div className="profileGeneral">
      <div className="profileSpokes">
        <h5 className="semi">Become a Spokesperson</h5>
        <p className="sm lh20">{text}</p>
        <button className="button">Apply Now</button>
      </div>
    </div>
  );
}
