import React from "react";
import { save } from "../../../../Base/SVG";
import { Link } from "react-router-dom";

export default function DeelItem(props) {
  return (
    <div className="deelItem">
      <Link to="/deels" className="reelItem__link"></Link>
      <div className="deelItem__inner">
        <div className="deelItem__image">
          <img src={process.env.PUBLIC_URL + props.image} alt="deels" />
        </div>
        <div className="deelItem__content">
          <h5>{props.title}</h5>
          <p>{props.text}</p>
        </div>
        <div className="deelItem__footer">
          <p>{props.saved} people saved this</p>
          <button className="button save">{save} Save</button>
        </div>
      </div>
    </div>
  );
}
