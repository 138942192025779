import React from "react";
import { Link } from "react-router-dom";
import { heart, locationIconEmpty } from "../../../../Base/SVG";
import Stars from "../../../../Base/Widgets/Stars";

export default function HouseList(props) {
  return (
    <Link to="/profile-apartment" className="listing">
      <div className="listing__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="" />
      </div>
      <div className="listing__content">
        <div className="listing__content-title">
          <h6 className="med">{props.title}</h6>
        </div>
        <div className="listing__content-name">
          <h6 className="sm">{props.name}</h6>
        </div>
        <div className="listing__content-location">
          {locationIconEmpty} {props.location}
        </div>
        <div className="listing__content-detail">
          <h6 className="sm">{props.detail}</h6>
        </div>
        <div className="listing__content-stars">
          <Stars starsRate={props.starsRate} />
        </div>
        <div className="listing__content-heart">{heart}</div>
      </div>
    </Link>
  );
}
