import React from "react";
import Slider from "react-slick";
import SaleItem from "./SaleItem";
import { dots, globalIcon } from "../../../../Base/SVG";
const sales = [
  {
    image: "/images/sales/sale-1.jpg",
    title: "$40 | Dog toys for sale | 3 toys in Less price",
    link: "google.com",
  },
  {
    image: "/images/sales/sale-2.jpg",
    title: "$40 | Dog toys for sale | 3 toys in Less price",
    link: "google.com",
  },
  {
    image: "/images/sales/sale-1.jpg",
    title: "$40 | Dog toys for sale | 3 toys in Less price",
    link: "google.com",
  },
  {
    image: "/images/sales/sale-2.jpg",
    title: "$40 | Dog toys for sale | 3 toys in Less price",
    link: "google.com",
  },
];
export default function Sales(props) {
  const { user } = props;

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          arrows: false,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div className="feed__box">
      <div className="sale">
        <div className="post__header">
          <div className="post__info">
            <div className="post__info-avatar">
              <img src={process.env.PUBLIC_URL + user.avatar} alt="avatar" />
            </div>
            <div className="post__info-content">
              <h4>{user.name}</h4>
              <h6>
                {user.location} <span></span> {user.date} <span></span>
                <a href={user.link}>{globalIcon}</a>
              </h6>
            </div>
          </div>
          <button type="button" className="dots">
            <div className="dots__btn">{dots}</div>
          </button>
        </div>
        <div className="sale__header">
          <h3>2 items for sale</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <div className="sale__body">
          <Slider {...settings}>
            {sales.map((item, index) => {
              return <SaleItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
