import React, { useState } from "react";
import ReactDOM from "react-dom";
import ModalDiscard from "./ModalDiscard/ModalDiscard";
import ModalEvent from "./ModalEvent/ModalEvent";
import ModalMain from "./ModalMain/ModalMain";
import ModalMap from "./ModalMap/ModalMap";
import ModalPoll from "./ModalPoll/ModalPoll";
import ModalSell from "./ModalSell/ModalSell";

const d = new Date();
let initStartTime = d;
initStartTime.setHours(12);
initStartTime.setMinutes(0);
const initForm = {
  mind: "",
  poll: {
    question: "",
    choice: ["", ""],
  },
  images: [],
  location: "",
  pEvent: {
    name: "",
    coverImage: "/images/modal/eventCover.png",
    startDate: new Date(),
    startTime: initStartTime,
    endDate: null,
    endTime: null,
    videoCallLink: "",
  },
};
export default function Modal({ active, close }) {
  const [discard, setDiscard] = useState(false);
  const [modal, setModal] = useState("main");
  const [form, setForm] = useState(initForm);
  const [expand, setExpand] = useState(false);
  const [modalName, setModalName] = useState(null);
  const [callLink, setCallLink] = useState(false);
  const [mindPlaceholder, setMindPlaceholder] = useState(
    "What’s on your mind?"
  );
  const updateImages = (target) => {
    setForm({ ...form, images: [...form.images, ...target.files] });
  };

  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  const closeOnFog = (e) => {
    if (e.target === e.currentTarget) close();
  };
  const mapBtnClick = () => {
    if (modalName === "event") {
      if (callLink) setCallLink(true);
      setModal("event");
      setForm({
        ...form,
        location: "440-450 Horner Avenue",
      });
    } else if (modalName === "main") {
      setModal("main");
      setForm({
        ...form,
        location: "440-450 Horner Avenue",
      });
    }
  };

  const discardBtnFunc = () => {
    if (modal === "main") {
      setForm(initForm);
      setDiscard(false);
      close();
    } else if (modal === "event") {
      setForm({
        ...form,
        location: "",
        mind: "",
        pEvent: {
          name: "",
          coverImage: "/images/modal/eventCover.png",
          startDate: new Date(),
          startTime: initStartTime,
          endDate: null,
          endTime: null,
          videoCallLink: "",
        },
      });
      setDiscard(false);
      setModal("main");
    } else if (modal === "poll") {
      setForm({
        ...form,
        poll: { question: "", choice: ["", ""] },
      });
      setDiscard(false);
      setModal("main");
    }
  };
  return ReactDOM.createPortal(
    <div className="modal" onClick={closeOnFog}>
      {modal === "main" && (
        <ModalMain
          initForm={initForm}
          setDiscard={setDiscard}
          setMindPlaceholder={setMindPlaceholder}
          mindPlaceholder={mindPlaceholder}
          modalName={modalName}
          initStartTime={initStartTime}
          close={close}
          form={form}
          updateForm={updateForm}
          expand={expand}
          setExpand={setExpand}
          setInput={setInput}
          setModal={setModal}
          setForm={setForm}
          updateImages={updateImages}
          setModalName={setModalName}
        />
      )}
      {modal === "poll" && (
        <ModalPoll
          setMindPlaceholder={setMindPlaceholder}
          setModal={setModal}
          form={form}
          updateForm={updateForm}
          setDiscard={setDiscard}
          initForm={initForm}
          setExpand={setExpand}
        />
      )}
      {modal === "sell" && <ModalSell close={close} setModal={setModal} />}
      {modal === "event" && (
        <ModalEvent
          initForm={initForm}
          setDiscard={setDiscard}
          form={form}
          setForm={setForm}
          setInput={setInput}
          callLink={callLink}
          setCallLink={setCallLink}
          pEvent={form.pEvent}
          setEvent={(data) => updateForm({ pEvent: data })}
          setExpand={setExpand}
          setModal={setModal}
          setModalName={setModalName}
        />
      )}
      {modal === "map" && (
        <ModalMap
          modalName={modalName}
          setModal={setModal}
          onClick={mapBtnClick}
        />
      )}
      {discard && (
        <ModalDiscard discardBtnFunc={discardBtnFunc} setDiscard={setDiscard} />
      )}
    </div>,
    document.getElementById("modals")
  );
}
