import React from "react";
import EmailInput from "../../../Base/Form/EmaiInput";
import TextInput from "../../../Base/Form/TextInput";
import { chevronLeft } from "../../../Base/SVG";
import PhoneInput from "../../../Base/Form/PhoneInput";
import { Link } from "react-router-dom";

export default function ProfileContact({ isActive, error, form, setForm }) {
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  return (
    <div className={"profileEdit " + (isActive === "contact" ? "visible" : "")}>
      <div className="profileEdit__header">
        <Link to="/profile" className="profileEdit__back">
          {chevronLeft}
        </Link>
        <h3>Contact Information</h3>
        <button type="button" className="save">
          Save
        </button>
      </div>
      <div className="profileEdit__inner">
        <PhoneInput
          placeholder="Enter Pets Number"
          label="Phone Number"
          type="number"
          errorText="Please enter your phone number"
          visibility={true}
          error={error}
          value={form.phone}
          onChange={onChangeInput("phone")}
        />
        <EmailInput
          placeholder="email@test.com"
          label="Email Address"
          type="email"
          errorText="Please enter your email address"
          visibility={true}
          error={error}
          value={form.email}
          onChange={onChangeInput("email")}
        />
        <TextInput
          placeholder="Enter Website"
          label="Website"
          type="text"
          errorText="Please enter your web address"
          error={error}
          visibility={true}
          value={form.website}
          onChange={onChangeInput("website")}
        />
        <TextInput
          placeholder="Enter City"
          label="City"
          type="text"
          errorText="Please enter your city"
          error={error}
          visibility={true}
          value={form.city}
          onChange={onChangeInput("city")}
        />
        <TextInput
          placeholder="Enter Country"
          label="Country"
          type="text"
          errorText="Please select your country"
          error={error}
          visibility={true}
          value={form.country}
          onChange={onChangeInput("country")}
        />
      </div>
      <div className="profileEdit__footer">
        <button type="button" className="formBtn clear">
          CLEAR
        </button>
        <button type="button" className="formBtn submit">
          SUBMIT
        </button>
      </div>
    </div>
  );
}
