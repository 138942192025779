import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { chevronRight, market, saved, user } from "./SVG";

export default function Filter({ filterOpen, setFilterOpen }) {
  const location = useLocation();
  useEffect(() => {
    if (filterOpen) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [filterOpen]);

  useEffect(() => {
    setFilterOpen(false);
    document.body.classList.remove("active");
  }, [location]);

  const closeFilter = (e) => {
    if (e.target === e.currentTarget) setFilterOpen(false);
  };

  return (
    <nav
      className={"filter " + (filterOpen ? "active" : "")}
      id="menu"
      onClick={closeFilter}
    >
      <div className="filter__inner">
        <div className="filter__inner-links">
          <NavLink to="/all-listing" className="filter__inner-link active">
            {market}
            <span> All listings</span>
          </NavLink>
          <NavLink to="/your-listing" className="filter__inner-link uniq">
            {user}
            <span>Your listings</span>
          </NavLink>
          <NavLink to="/saved-listing" className="filter__inner-link">
            {saved}
            <span>Saved listings</span>
          </NavLink>
        </div>
        <div className="filter__inner-filter">
          <h6 className="sm">FILTER</h6>
          <div className="checkr__row">
            <h5>Buy for Good</h5>
            <div className="checkr">
              <input type="checkbox" />
              <label htmlFor=""></label>
            </div>
          </div>
          <div className="checkr__row">
            <h5>Free</h5>
            <div className="checkr">
              <input type="checkbox" />
              <label htmlFor=""></label>
            </div>
          </div>
          <div className="checkr__row">
            <h5>Discounted</h5>
            <div className="checkr">
              <input type="checkbox" />
              <label htmlFor=""></label>
            </div>
          </div>
        </div>

        <div className="filter__inner-filter">
          <h6 className="sm">SHOW ITEMS FROM</h6>
          <button type="button" className="button connect dark">
            Buy for Good {chevronRight}
          </button>
        </div>
        <div className="filter__inner-filter">
          <h6 className="sm">CATEGORIES</h6>
          <div className="checkt">
            <input defaultChecked={true} type="radio" name="category" />
            <label htmlFor="">All categories</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Toys</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Beds</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Foods</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Hygiene</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Grooming</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Clothing</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Leashes</label>
          </div>
          <div className="checkt">
            <input type="radio" name="category" />
            <label htmlFor="">Accessory</label>
          </div>
        </div>
        <div className="filter__inner-copy">
          <p className="sm">
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <p className="sm">
            <Link to="/terms">Terms and Conditions</Link>
          </p>
          <p className="sm">2022 copyright. all rights reserved</p>
        </div>
      </div>
    </nav>
  );
}
