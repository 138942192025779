import React, { useState } from "react";
import { buss, chevronLeft, chevronRight, pet, plus } from "../../../Base/SVG";
import { Link } from "react-router-dom";

export default function ProfileMenu({ setProfileMenu, menuType }) {
  return (
    <div className="profileMenu">
      <div
        className="profileMenu__header"
        onClick={() => setProfileMenu(false)}
      >
        {chevronLeft}
        <h3>{menuType === "owner" ? "Profile Menu" : "Edit Pet Profile"}</h3>
      </div>
      <div className="profileMenu__inner">
        <div className="profileMenu__inner-content">
          {menuType === "owner" ? (
            <>
              <div className="profileMenu__inner-links">
                <h6>SECTION</h6>
                <Link to="" className="profileMenu__inner-link">
                  {pet}All Pets Profile <span>{chevronRight}</span>
                </Link>
                <Link to="/add-pet" className="profileMenu__inner-link">
                  {plus}Add New Pet <span>{chevronRight}</span>
                </Link>
              </div>
              <div className="profileMenu__inner-links">
                <h6>My Businesses</h6>
                <Link to="" className="profileMenu__inner-link">
                  {buss}All Businesses <span>{chevronRight}</span>
                </Link>
                <Link to="/add-business" className="profileMenu__inner-link">
                  {plus}Add New Business <span>{chevronRight}</span>
                </Link>
              </div>
              <div className="profileMenu__inner-links">
                <h6>Support</h6>
                <Link to="" className="profileMenu__inner-link">
                  {pet}Help center <span>{chevronRight}</span>
                </Link>
                <Link to="" className="profileMenu__inner-link">
                  {pet}Privacy Policy <span>{chevronRight}</span>
                </Link>
                <Link to="" className="profileMenu__inner-link">
                  {pet}Member Agreement <span>{chevronRight}</span>
                </Link>
                <Link to="" className="profileMenu__inner-link">
                  {pet}Share this app <span>{chevronRight}</span>
                </Link>
                <Link to="" className="profileMenu__inner-link">
                  {pet}Data Protection <span>{chevronRight}</span>
                </Link>
              </div>
            </>
          ) : (
            <div className="profileMenu__inner-links">
              <h6>SECTION</h6>
              <Link
                className="profileMenu__inner-link"
                state={{ tab: "profilePhoto" }}
                to="/edit-profile"
              >
                {pet}Profile Photo <span>{chevronRight}</span>
              </Link>
              <Link
                state={{ tab: "coverPhoto" }}
                to="/edit-profile"
                className="profileMenu__inner-link"
              >
                {pet}Cover Photo <span>{chevronRight}</span>
              </Link>
              <Link
                state={{ tab: "general" }}
                to="/edit-profile"
                className="profileMenu__inner-link"
              >
                {pet}General information <span>{chevronRight}</span>
              </Link>
              <Link
                state={{ tab: "contact" }}
                to="/edit-profile"
                className="profileMenu__inner-link"
              >
                {pet}Contact information <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Emergency Contact <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Lost n Found pet ID <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Training & Achievements <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Health & Wellness <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Pet References <span>{chevronRight}</span>
              </Link>
              <Link className="profileMenu__inner-link">
                {pet}Community Pet Rules <span>{chevronRight}</span>
              </Link>
            </div>
          )}
        </div>
        <div className="profileMenu__inner-footer">
          <h6>MyPetsNeighborhood™</h6>
          <p className="sm">Version 0.0.1</p>
        </div>
      </div>
    </div>
  );
}
