import React from "react";

export default function Checkbox(props) {
  return (
    <div className="checkbox">
      <input
        ref={props.checkRef}
        checked={props.checked}
        type={props.type}
        onChange={props.onChange}
      />
      <label htmlFor="type">{props.option}</label>
      <span>{props.text}</span>
    </div>
  );
}
