import React from "react";
import { usersThreeFilled } from "../../../../Base/SVG";

export default function ProfileCommPortal() {
  return (
    <div className="profileGeneral">
      <div className="profileMoreInfo">
        <h5 className="semi">Community Portal</h5>
        <a href="#" className="profileMoreInfo__link">
          {usersThreeFilled} <h6 className="med">Visit Group</h6>
        </a>
      </div>
    </div>
  );
}
