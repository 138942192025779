import React from "react";
import { mapIcon } from "../../../../Base/SVG";

export default function ProfileMap() {
  return (
    <div className="profileGeneral">
      <div className="profileMap">
        <div className="profileMap__title">
          {mapIcon} <h6 className="med">Boulevart, Sechelt, CA-BC</h6>
        </div>
        <div className="profileMap__inner">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.3837130424486!2d-106.87690998404983!3d52.83347052070267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53041f0a6a6707d5%3A0x3005d58c2ab7bb2a!2sHorner%20Ave%2C%20Blaine%20Lake%2C%20SK%20S0J%200J0!5e0!3m2!1sru!2sca!4v1674230584344!5m2!1sru!2sca"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
