import React from "react";
import { Link, NavLink } from "react-router-dom";
import { filter, plus, search } from "./SVG";
import Messages from "./Widgets/Messages";
import Avatar from "./Avatar";
import Notification from "./Widgets/Notification";

export default function Header2({ filterOpen, setFilterOpen, setModal }) {
  return (
    <div className="header2">
      <div className="auto__container">
        <div className="header2__inner">
          <div className="header2__inner-col">
            <Link to="" className="header2__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </Link>
            <div className="search">
              <input type="text" placeholder="Search anything pet-friendly" />
              <button type="button">{search}</button>
            </div>
            <button
              type="button"
              className="filterBtn"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filter}
            </button>
          </div>
          <div className="header2__inner-col">
            <div className="header2__inner-links">
              <NavLink to="/" className="header2__inner-link">
                Home
              </NavLink>
              <NavLink to="/explore" className="header2__inner-link">
                Explore
              </NavLink>
              <NavLink to="/marketplace" className="header2__inner-link">
                Marketplace
              </NavLink>
            </div>
            <div className="header2__inner-row">
              <div className="button post" onClick={() => setModal(true)}>
                {plus} <span>Post</span>
              </div>
              <Messages />
              <Notification />
              <Avatar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
