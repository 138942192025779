import React, { useEffect, useRef, useState } from "react";
import { gang, global, group, lock } from "../SVG";
import { Link } from "react-router-dom";

export default function Visibility() {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  const clickListener = React.useCallback((e) => {
    const className = e.target.classList[0];
    if (!className || !className.startsWith("account")) setActive(false);
    if (className === "account__list-link") setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      setTimeout(() => document.addEventListener("click", clickListener), 100);
    } else document.removeEventListener("click", clickListener);
    return () => document.removeEventListener("click", clickListener);
  }, [active]);
  return (
    <div className="visibility" ref={wrapper}>
      <div className="visibility__btn" onClick={toggleActive}>
        {global}
      </div>
      <div className={`dropdown  ${active ? "active" : ""} `}>
        <button type="button" className="dropdown__link">
          {global} Public
        </button>
        <button type="button" className="dropdown__link">
          {gang} All Members
        </button>
        <button type="button" className="dropdown__link">
          {group} My Connections
        </button>
        <button type="button" className="dropdown__link">
          {lock} Only Me
        </button>
      </div>
    </div>
  );
}
