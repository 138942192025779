import React, { useEffect, useRef, useState } from "react";
import {
  dislike,
  dots,
  globalIcon,
  heart,
  heartEmpty,
  like,
  message,
  photoIcon,
  share,
  shareIcon,
} from "../../../../Base/SVG";
import { users } from "./Moduls";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import TextInput from "../../../../Base/Form/TextInput";

export default function Post(props) {
  const commentWrapper = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [focus, setFocus] = useState(false);
  const { user, post } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
    arrows: false,
  };
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!commentWrapper.current.contains(target)) setFocus(false);
    };

    if (focus) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [focus]);
  return (
    <div className="feed__box">
      <div className="post">
        <div className="post__header">
          <div className="post__info">
            <div className="post__info-avatar">
              <img src={process.env.PUBLIC_URL + user.avatar} alt="avatar" />
            </div>
            <div className="post__info-content">
              <h4>{user.name}</h4>
              <h6>
                {user.location} <span></span> {user.date} <span></span>
                <a href={user.link}>{globalIcon}</a>
              </h6>
            </div>
          </div>
          <button type="button" className="dots">
            <div className="dots__btn">{dots}</div>
          </button>
        </div>
        <div className="post__body">
          {post.images && (
            <div className="postSlider">
              <Slider {...settings}>
                {post.images.map((item, index) => {
                  return (
                    <div className="postSlider__item" key={index}>
                      <div className="postSlider__item-image">
                        <img src={process.env.PUBLIC_URL + item} alt="image" />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}

          {post.text && (
            <p>
              {showMore ? post.text : post.text.toString().substring(0, 150)}
              {post.text && post.text.length > 150 && (
                <button type="button" onClick={() => setShowMore(!showMore)}>
                  {showMore ? ". . .Show less" : ". . . Show more"}
                </button>
              )}
            </p>
          )}
          {post.image && (
            <div className="post__body-image">
              <img src={process.env.PUBLIC_URL + post.image} alt="image" />
            </div>
          )}
          {post.link && (
            <>
              <p>
                <a href={post.link} className="httpLink">
                  {post.link}
                </a>
              </p>
              <Link to={post.link} className="facebookLink">
                <div className="facebookLink__box">
                  <div className="facebookLink__image">
                    <img
                      src={process.env.PUBLIC_URL + post.linkImage}
                      alt="image"
                    />
                  </div>
                  <div className="facebookLink__info">
                    <h6 className="semi">{post.linkTitle}</h6>
                    <p className="sm">{post.linkText}</p>
                  </div>
                </div>
              </Link>
            </>
          )}
          {post.phone && (
            <>
              <h6 className="semi mb">{post.phone}</h6>
              <div className="facebookLink">
                <div className="facebookLink__box">
                  <div className="facebookLink__image">
                    <img
                      src={process.env.PUBLIC_URL + post.linkImage}
                      alt="image"
                    />
                  </div>
                  <div className="facebookLink__info">
                    <h6 className="semi">{post.linkTitle}</h6>
                    <p className="sm">{post.linkText}</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {post.question && (
            <>
              <p>{props.text}</p>
              <div className="post__quest">
                <h5 className="semi">{post.question}</h5>
                <div className="post__quest-btns">
                  <button type="button" className="button bool yes">
                    {like}
                    Yes
                  </button>
                  <button type="button" className="button bool no">
                    {dislike}
                    No
                  </button>
                </div>
              </div>
            </>
          )}
          {post.reply && (
            <div className="post">
              <div className="post__header">
                <div className="post__info">
                  <div className="post__info-avatar">
                    <img
                      src={process.env.PUBLIC_URL + users.SM.avatar}
                      alt="avatar"
                    />
                  </div>
                  <div className="post__info-content">
                    <h4>{users.SM.name}</h4>
                    <h6>
                      {users.SM.location} <span></span> {users.SM.date}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="post__body">
                {post.text && (
                  <p>
                    {post.text} <a href={post.more}>See more</a>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="post__footer">
          <div className="post__likes">
            {heart}
            <span>
              {post.likes} <span className="remove">Likes</span>
            </span>
          </div>
          <div className="post__btns">
            <button type="button" className="post__btn">
              {heartEmpty}
              <span>Like</span>
            </button>
            <button type="button" className="post__btn">
              {message}
            </button>
            <button type="button" className="post__btn">
              {share}
            </button>
          </div>
        </div>
        <div className="post__comment">
          <div className="post__comment-user">
            <div className="post__comment-user-image">
              <img
                src={process.env.PUBLIC_URL + "/images/avatar/avatar.png"}
                alt="avatar"
              />
            </div>
            <div className="post__comment-user-content" ref={commentWrapper}>
              <TextInput
                placeholder="Add a comment"
                onFocus={() => setFocus(true)}
              />
              {focus && (
                <div className="post__comment-user-btns">
                  <div className="post__comment-user-btns-row">
                    <button className="button comment">
                      {photoIcon} <span>Add photo or video</span>
                    </button>
                    <button className="button comment">
                      {shareIcon} <span>Add location</span>
                    </button>
                  </div>
                  <button className="button comment green">Comment</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
