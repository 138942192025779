import React from "react";
import { bell } from "../SVG";

export default function Notification() {
  return (
    <div className="notification">
      <div className="notification__btn">{bell}</div>
    </div>
  );
}
