import React from "react";
import { dollarIcon, heart, locationIconEmpty } from "../../../../Base/SVG";

export default function ProductList(props) {
  return (
    <div className="listing">
      <div className="listing__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="" />
      </div>
      <div className="listing__content">
        <div className="listing__content-title">
          <h6 className="med">{props.title}</h6>
        </div>
        <div className="listing__content-name">
          <h6 className="sm">{props.name}</h6>
        </div>
        <div className="listing__content-location big">
          {locationIconEmpty} {props.location}
        </div>
        <div className="listing__content-price">
          {props.price && (
            <>
              {dollarIcon} <h6 className="sm">{props.price}</h6>
            </>
          )}
          {props.free && <h6 className="sm free">FREE</h6>}
        </div>
        <div className="listing__content-heart">{heart}</div>
      </div>
    </div>
  );
}
