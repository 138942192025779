import React, { useState } from "react";
import { filterIcon } from "../../Base/SVG";
import ExploreFilter from "./components/ExploreFilter";
import ExploreListing from "./components/ExploreListing";
import ExploreMap from "./components/ExploreMap/ExploreMap";

export default function Explore({ exploreFilter, setExploreFilter }) {
  const [listView, setListView] = useState(true);
  return (
    <div className={"explore " + (listView ? "active" : "")}>
      <div className="explore__inner">
        <div className="explore__title">
          <h3>
            <span>Explore</span> MyPetsNeighborhood
          </h3>
          <button
            className="button filterbtn"
            onClick={() => setExploreFilter(!exploreFilter)}
          >
            {filterIcon} Filter
          </button>
        </div>
        <div className="explore__content">
          {!exploreFilter && (
            <ExploreListing listView={listView} setListView={setListView} />
          )}
          {exploreFilter && (
            <ExploreFilter
              exploreFilter={exploreFilter}
              setExploreFilter={setExploreFilter}
            />
          )}
          <ExploreMap listView={listView} setListView={setListView} />
        </div>
      </div>
    </div>
  );
}
