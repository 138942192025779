import React from "react";

export default function RadioInputPost(props) {
  return (
    <div className="radio">
      <input
        checked={props.checked}
        name={props.type}
        type={props.type}
        onChange={props.onChange}
      />
      <label htmlFor="type">{props.option}</label>
    </div>
  );
}
