import React from "react";
import { Link } from "react-router-dom";

export default function NewsItem(props) {
  return (
    <div className="newsItem">
      <Link to="/news" className="reelItem__link"></Link>
      <div className="newsItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="reels" />
      </div>
      <div className="newsItem__inner">
        <div className="newsItem__header">
          <h6 className="sm">{props.date}</h6>
          <h6 className="sm uniq">{props.name}</h6>
        </div>
        <div className="newsItem__body">
          <h6 className="semi">{props.title}</h6>
          <h6 className="sm med">{props.tags}</h6>
        </div>
      </div>
    </div>
  );
}
