import React from "react";
import { Link } from "react-router-dom";

export default function MppItem(props) {
  return (
    <div className="mppItem">
      <Link to="/mpp" className="reelItem__link"></Link>

      <div className="mppItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="reels" />
      </div>
      <h5 className="semi">{props.title}</h5>
    </div>
  );
}
