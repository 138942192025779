import React from "react";
import DatePicker from "react-datepicker";
import Visibility from "./Visibility";

export default function DateInput(props) {
  return (
    <div className="input__outer">
      <div className="input__header">
        {props.label && <label>{props.label}</label>}
        {props.visibility && <Visibility />}
      </div>
      <div className="input">
        <DatePicker
          selected={props.selected}
          onChange={props.onChange}
          startDate={props.startDate}
          placeholderText={props.placeholder}
          minDate={props.minDate}
          showTimeSelect={props.showTimeSelect}
          showTimeSelectOnly={props.showTimeSelectOnly}
          timeIntervals={props.timeIntervals}
          dateFormat={props.dateFormat}
        />
      </div>
      {props.startDate === "" && (
        <div className="input__error">Please enter your pets DOB</div>
      )}
    </div>
  );
}
