import React from "react";
import Slider from "react-slick";
import MppItem from "./MppItem";
const mpps = [
  {
    image: "/images/mpp/mpp-1.png",
    title: "Digital ID",
  },
  {
    image: "/images/mpp/mpp-2.png",
    title: "Digital ID",
  },
  {
    image: "/images/mpp/mpp-3.png",
    title: "Digital ID",
  },
];
export default function Mpp() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2.6,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div className="feed__box">
      <div className="mpp">
        <div className="mpp__header">
          <h3 className="bold">MPP Profile IDs</h3>
          <a href="" className="link">
            See all
          </a>
        </div>
        <div className="mpp__body">
          <Slider {...settings}>
            {mpps.map((item, index) => {
              return <MppItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
