import React from "react";

export default function ModalDiscard({ setDiscard, discardBtnFunc }) {
  return (
    <div className="modal__discard">
      <div className="modal__discard-inner">
        <div className="modal__discard-title">Discard post?</div>
        <div className="modal__discard-subtitle">
          If you go back, it won't be saved.
        </div>
        <button className="button discard" onClick={discardBtnFunc}>
          Discard
        </button>
        <button
          className="button discard cancel"
          onClick={() => {
            setDiscard(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
